import React, { useEffect, useState } from 'react';
import './AgencyProfessionalsEditEmployee.css';
import { Form } from 'react-bootstrap';
import Joi from 'joi';
import { useDispatch } from 'react-redux';
import WithOnlyOnDesktop from '../../components/onlyOnDesktop/withOnlyOnDesktop';
import Select from '../../components/form/Select';
import { useDropdownsQuery } from '../../redux/dropdowns';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import { useAgencyProfessionalQuery, useUpdateAgencyProfessionalMutation } from '../../redux/external-agency';
import Notification from '../../components/notification/Notification';
import { showNotification } from '../../redux/notification';

const defaultFormValues = {
	address: '',
	city_name: '',
	email: '',
	first_name: '',
	last_name: '',
	preferred_contact_method: '',
	zip_code: '',
	state_code: ''
};

const validateForm = (formData) => {
	const professionalSchema = Joi.object({
		first_name: Joi.string()
			.max(50)
			.trim()
			.pattern(/^[a-zA-Z -]+$/)
			.required()
			.messages({
				'string.empty': 'First Name is required',
				'string.base': 'First Name is required',
				'any.required': 'First Name is required',
				'string.max': 'First Name can have a maximum length of 50 characters',
				'string.pattern.base': 'First Name is not valid'
			}),
		last_name: Joi.string()
			.max(50)
			.trim()
			.pattern(/^[a-zA-Z -]+$/)
			.required()
			.messages({
				'string.empty': 'Last Name is required',
				'string.base': 'Last Name is required',
				'any.required': 'Last Name is required',
				'string.max': 'Last Name can have a maximum length of 50 characters',
				'string.pattern.base': 'Last Name is not valid'
			}),
		address: Joi.string().max(255).trim().required().messages({
			'string.empty': 'Address is required',
			'string.base': 'Address is required',
			'any.required': 'Address is required',
			'string.max': 'Address can have a maximum length of 255 characters'
		}),
		city_name: Joi.string().required().trim().messages({
			'string.empty': 'City is required',
			'string.base': 'City is required',
			'any.required': 'City is required'
		}),
		state_code: Joi.string().required().messages({
			'string.empty': 'State is required',
			'string.base': 'State is required',
			'any.required': 'State is required',
			'any.only': 'State is required'
		}),
		zip_code: Joi.string()
			.min(5)
			.max(5)
			.pattern(/^[0-9]{5}$/)
			.trim()
			.required()
			.messages({
				'string.base': 'Zip Code is required',
				'string.empty': 'Zip Code is required',
				'string.min': 'Zip Code is not valid',
				'string.max': 'Zip Code is not valid',
				'string.pattern.base': 'Zip code is not valid'
			}),
		email: Joi.string()
			.max(129)
			.trim()
			.email({ tlds: { allow: false } })
			.required()
			.messages({
				'string.base': 'Email is required',
				'string.empty': 'Email is required',
				'any.required': 'Email is required',
				'string.email': 'Email format is invalid',
				'string.max': 'Email can have a maximum length of 129 characters'
			}),
		preferred_contact_method: Joi.string().valid('EMAIL', 'CALL_AND_TEXT').required().messages({
			'string.empty': 'Preferred Contact Method is required',
			'string.base': 'Preferred Contact Method is required',
			'any.required': 'Preferred Contact Method is required',
			'any.only': 'Preferred Contact Method must be either Email or Call and Text'
		})
	});

	const { error } = professionalSchema.validate(formData, { abortEarly: false });
	const formErrors = error?.details.reduce((acc, curr) => {
		acc[curr.context.key] = curr.message;
		return acc;
	}, {});

	return formErrors;
};

const AgencyProfessionalsEditEmployee = ({ caregiverId, setEditMode }) => {
	const [formData, setFormData] = useState(defaultFormValues);
	const [formErrors, setFormErrors] = useState(defaultFormValues);
	const dispatch = useDispatch();

	const { data: professional, isLoading } = useAgencyProfessionalQuery(caregiverId, {
		refetchOnMountOrArgChange: true
	});
	const { isLoading: isPCMLoading, data: preferredContactMethod } = useDropdownsQuery('preferred-contact-methods');
	const { isLoading: isStatesLoading, data: states } = useDropdownsQuery('states');
	const [updateProfessional, { isLoading: isSaving }] = useUpdateAgencyProfessionalMutation();

	useEffect(() => {
		setFormData({
			address: professional.address,
			city_name: professional.city_name,
			email: professional.email,
			preferred_contact_method: professional.preferred_contact_method,
			zip_code: professional.zip_code,
			state_code: professional.state_code,
			first_name: professional.first_name,
			last_name: professional.last_name
		});
	}, [professional]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSelectChange = (value) => {
		setFormData({ ...formData, ...value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const errors = validateForm(formData);
		setFormErrors((prevErrors) => ({
			...prevErrors,
			...defaultFormValues,
			...errors
		}));

		const trimmedFormData = Object.fromEntries(Object.entries(formData).map(([key, value]) => [key, value?.trim()]));

		if (!errors) {
			try {
				await updateProfessional({ caregiverId, body: trimmedFormData }).unwrap();
				setEditMode(false);
			} catch (error) {
				const currentError = error.data.errors[0];
				if (currentError.field) {
					setFormErrors((prevErrors) => ({
						...prevErrors,
						...defaultFormValues,
						[currentError.field]: currentError.message
					}));
				}

				dispatch(showNotification('error', currentError.message));
			}
		}
	};

	if (isStatesLoading || isPCMLoading || isLoading) {
		return (
			<div className="spinner-center-wrapper" key="spinner">
				<LoadingSpinner className="saving-spinner" />
			</div>
		);
	}

	return (
		<WithOnlyOnDesktop>
			<div className="employee-details__wrapper">
				<Notification />
				<Form onSubmit={handleSubmit}>
					<div className="employee-details">
						<h2>Name</h2>
						<Form.Group controlId="first_name" className="form-input-group">
							<Form.Label>First Name</Form.Label>
							<Form.Control
								size="lg"
								type="text"
								name="first_name"
								required
								className="form-input"
								onChange={handleChange}
								value={formData.first_name}
								isInvalid={formErrors.first_name}
							/>
							<Form.Control.Feedback type="invalid">{formErrors.first_name}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="last_name" className="form-input-group">
							<Form.Label>Last Name</Form.Label>
							<Form.Control
								size="lg"
								type="text"
								name="last_name"
								required
								className="form-input"
								onChange={handleChange}
								value={formData.last_name}
								isInvalid={formErrors.last_name}
							/>
							<Form.Control.Feedback type="invalid">{formErrors.last_name}</Form.Control.Feedback>
						</Form.Group>
					</div>
					<div className="employee-details">
						<h2>Licenses</h2>
						<div className="licenses-wrapper">
							<h3>You can’t edit professional’s licenses at this time.</h3>
						</div>
					</div>
					<div className="employee-details">
						<h2>Home Address</h2>
						<Form.Group controlId="address" className="form-input-group">
							<Form.Label>Street Address</Form.Label>
							<Form.Control
								size="lg"
								type="text"
								className="form-input"
								name="address"
								required
								onChange={handleChange}
								value={formData.address}
								isInvalid={formErrors.address}
							/>
							<Form.Control.Feedback type="invalid">{formErrors.address}</Form.Control.Feedback>
						</Form.Group>
						<div className="input-group">
							<Form.Group controlId="state_code" className="form-input-group location-input-group">
								<Form.Label className="form-label">State</Form.Label>
								<Select
									className="select employee-edit-page "
									name="state_code"
									options={states}
									value={formData.state_code}
									handleChange={handleSelectChange}
								/>
								{formErrors.state_code && <div className="invalid-feedback-custom">{formErrors.state_code}</div>}
							</Form.Group>
							<Form.Group controlId="city_name" className="form-input-group location-input-group">
								<Form.Label>City</Form.Label>
								<Form.Control
									size="lg"
									type="text"
									className="form-input"
									name="city_name"
									required
									onChange={handleChange}
									value={formData.city_name}
									isInvalid={formErrors.city_name}
								/>
								<Form.Control.Feedback type="invalid">{formErrors.city_name}</Form.Control.Feedback>
							</Form.Group>
							<Form.Group controlId="zip_code" className="form-input-group location-input-group">
								<Form.Label>ZIP</Form.Label>
								<Form.Control
									size="lg"
									type="text"
									className="form-input"
									name="zip_code"
									onChange={handleChange}
									value={formData.zip_code}
									isInvalid={formErrors.zip_code}
								/>
								<Form.Control.Feedback type="invalid">{formErrors.zip_code}</Form.Control.Feedback>
							</Form.Group>
						</div>
					</div>
					<div className="employee-details">
						<h2>Contact Information</h2>
						<Form.Group controlId="preferred_contact_method" className="form-input-group">
							<Form.Label>Preferred contact method</Form.Label>
							<Select
								className="select employee-edit-page "
								name="preferred_contact_method"
								options={preferredContactMethod}
								value={formData.preferred_contact_method}
								handleChange={handleSelectChange}
							/>
							{formErrors.preferred_contact_method && (
								<div className="invalid-feedback-custom">{formErrors.preferred_contact_method}</div>
							)}
							<Form.Text className="form-text">
								Please note: Automated notifications will not be impacted by this selection.
							</Form.Text>
						</Form.Group>
						<Form.Group controlId="phone_number" className="form-input-group">
							<Form.Label className="disabled">Phone</Form.Label>
							<Form.Control
								size="lg"
								type="text"
								className="form-input"
								name="phone_number"
								disabled
								value={professional.phone_number}
							/>
						</Form.Group>
						<Form.Group controlId="email" className="form-input-group">
							<Form.Label>Email</Form.Label>
							<Form.Control
								size="lg"
								type="text"
								className="form-input"
								name="email"
								onChange={handleChange}
								value={formData.email}
								isInvalid={formErrors.email}
							/>
							<Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
						</Form.Group>
					</div>
				</Form>
				<div className="form-actions">
					<button type="button" className="form-actions__button secondary-button" onClick={() => setEditMode(false)}>
						Cancel
					</button>
					<button
						type="submit"
						className="form-actions__button primary-button"
						onClick={handleSubmit}
						disabled={isSaving}
					>
						Save changes
					</button>
				</div>
			</div>
		</WithOnlyOnDesktop>
	);
};

export default AgencyProfessionalsEditEmployee;
