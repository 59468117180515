import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useHistory } from 'react-router';
import utils from '../../utils/utils';
import WithOnlyOnDesktop from '../../components/onlyOnDesktop/withOnlyOnDesktop';
import AgencyOpenShifts from './AgencyOpenShifts';
import AgencyAssignedShifts from './AgencyAssignedShifts';

import './AgencyScreen.scss';
import './AgencySchedule.scss';

const GLOBAL_SEARCH_TAB_INDEXES = [1];

const AgencySchedule = () => {
	const history = useHistory();

	const _defaultIndex = Number(utils.getURLQueryParams()?.['tab-index'] || 0);
	const [activeTabIndex, setActiveTabIndex] = useState(_defaultIndex);
	const [searchTerm, setSearchTerm] = useState('');

	const onSearchTermUpdate = (e) => setSearchTerm(e.target.value);

	const onChangeTab = (e) => {
		setSearchTerm('');
		setActiveTabIndex(e.index);
		history.push(`${history.location.pathname}?tab-index=${e.index}`);
	};

	return (
		<WithOnlyOnDesktop>
			<div className="external-agency-screen external-agency-schedule">
				<div className="d-flex align-items-center">
					<div className="title flex-one">Schedule</div>
					{GLOBAL_SEARCH_TAB_INDEXES.includes(activeTabIndex) && (
						<span className="global-search-container">
							<input
								type="text"
								value={searchTerm}
								onChange={onSearchTermUpdate}
								placeholder="Search facility, location, rate, resource type, and more"
							/>
						</span>
					)}
				</div>
				<TabView activeIndex={activeTabIndex} onTabChange={onChangeTab}>
					<TabPanel header="Open">
						<AgencyOpenShifts />
					</TabPanel>
					<TabPanel header="Assigned">
						<AgencyAssignedShifts searchTerm={searchTerm} />
					</TabPanel>
					<TabPanel header="Past" />
				</TabView>
			</div>
		</WithOnlyOnDesktop>
	);
};

export default AgencySchedule;
