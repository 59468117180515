import React, { useState } from 'react';
import useSearchDebounce from '../../../hooks/useSearchDebounce';
import CaregiverSearchControl from '../../../components/modals/CaregiverShiftActionModals/CaregiverSearchControl';
import LoadingSpinner from '../../../components/spinner/LoadingSpinner';
import ShiftDetails from './shift-details/ShiftDetails';

import './AgencyAssign.scss';

const AgencyAssign = ({ shift, removeCaregiver, addCaregiver, selectedCaregivers, showLoadingSpinner }) => {
	const [loading, setLoading] = useState(false);
	const [errorSearch, setErrorSearch] = useState(false);
	const [showSelectedProfessionals, setShowSelectedProfessionals] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [searchedCaregivers, setSearchCaregivers] = useState([]);

	const handleResults = (results = []) => {
		const caregiversHaystack = new Set(selectedCaregivers.map((c) => c.caregiver_id));
		const filterResults = results.filter((c) => !caregiversHaystack.has(c.caregiver_id));
		setSearchCaregivers(filterResults);
	};
	const shiftId = shift.is_recurring ? shift.recurring_shift_id : shift.shift_id;
	const searchURL = `/agencies/shift/${shiftId}/search-professionals`;
	const { searchTermChange } = useSearchDebounce(
		searchURL,
		searchValue,
		{},
		setSearchValue,
		setLoading,
		setErrorSearch,
		handleResults,
		3
	);

	const addSelectedCaregiver = (caregiver, clearSelectedCaregivers) => {
		setSearchValue('');
		setSearchCaregivers([]);
		setShowSelectedProfessionals(true);
		addCaregiver(caregiver, clearSelectedCaregivers);
	};

	const removeSelectedProfessional = (evt, caregiver) => {
		if (evt) evt.preventDefault();
		setShowSelectedProfessionals(false);
		removeCaregiver(caregiver);
	};

	return (
		<div className="assign-professional-container">
			<ShiftDetails shift={shift} />
			<br />
			<CaregiverSearchControl
				addSelectedCaregiver={addSelectedCaregiver}
				errorSearch={errorSearch}
				searchValue={searchValue}
				searchTermChange={searchTermChange}
				selectedCaregivers={selectedCaregivers}
				removeSelectedProfessional={removeSelectedProfessional}
				loading={loading}
				searchedCaregivers={searchedCaregivers}
				showSelectedProfessionals={showSelectedProfessionals}
				copy={false}
				inputPlaceholder="Search professional with matching shift type"
				searchSectionTitle="Search and Select Professional"
				selectedSectionTitle="Selected Professional"
				allowSingleCaregiverSelection
			/>
			{showLoadingSpinner && <LoadingSpinner key="loading-assign-professional" />}
		</div>
	);
};

export default AgencyAssign;
