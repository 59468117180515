import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import useOnClickOutside from '../components/hooks/useOnClickOutside';

import { PRIVATE_PATH } from '../constants';

import { ArrowLeftIcon, CaretIcon } from '../components/icons';
import LoadingSpinner from '../components/spinner/LoadingSpinner';
import Sidebar from '../components/sidebar/Sidebar';
import Dropdown from '../components/form/Dropdown';

import './SidebarWrapper.scss';

const SidebarWrapper = ({ children, className, isLoading, sidebarItems, sidebarTitle, backTitle, backAction }) => {
	const [open, setOpen] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const containerRef = useRef(null);
	useOnClickOutside(containerRef, () => setOpen(false), 'mousedown');

	const currentTitle = sidebarItems.find(
		(item) => location.pathname === item.path || location.pathname === PRIVATE_PATH.ACCOUNT
	)?.label;
	const additionalAction = sidebarItems.find(
		(item) => location.pathname === item.path || location.pathname === PRIVATE_PATH.ACCOUNT
	)?.additionalAction;

	const editTitle = sidebarItems.find(
		(item) => location.pathname === item.path || location.pathname === PRIVATE_PATH.ACCOUNT
	)?.editTitle;
	const isEditing = sidebarItems.find(
		(item) => location.pathname === item.path || location.pathname === PRIVATE_PATH.ACCOUNT
	)?.isEditing;

	const menuItems = sidebarItems.map((item) => ({
		label: item.label,
		onClick: () => {
			setOpen(false);
			if (item.path) {
				history.push(item.path);
			}
		},
		selected: location.pathname === item.path || location.pathname === PRIVATE_PATH.ACCOUNT
	}));

	const agencyPageClassName = backTitle ? 'agency-page' : '';

	return (
		<div className={classNames('sidebar-wrapper__container', className, agencyPageClassName)}>
			<div className="sidebar-wrapper__page">
				<div className="sidebar-wrapper_menu">
					{backTitle && (
						<button type="button" className="sidebar-back__action__title" onClick={backAction}>
							<ArrowLeftIcon />
							{backTitle}
						</button>
					)}
					<Sidebar className="sidebar">
						<h1>{sidebarTitle}</h1>
						{sidebarItems.map(({ label, path }) => (
							<Sidebar.Link key={path} label={label} to={path} className={`${backTitle ? 'sidebar_menu' : ''}`} />
						))}
					</Sidebar>
				</div>
				<div className="sidebar-wrapper__screen">
					{isLoading && (
						<div className="spinner-wrapper">
							<LoadingSpinner key="loading-spinner" />
						</div>
					)}
					<div ref={containerRef}>
						<div className="title-wrapper">
							<h2>{isEditing ? editTitle : currentTitle}</h2>
							<button
								type="button"
								className="toggle-section"
								onClick={() => setOpen(!open)}
								aria-label="Toggle sidebar"
							>
								<CaretIcon pointTo={open ? 'up' : 'down'} />
							</button>
							<div className="action">{additionalAction}</div>
						</div>
						<Dropdown open={open} menuItems={menuItems} className="sidebar-wrapper__dropdown" />
					</div>
					{children}
				</div>
			</div>
		</div>
	);
};

export default SidebarWrapper;
