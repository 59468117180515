/* eslint-disable no-param-reassign */
import api from './api';

const apiWithTags = api.enhanceEndpoints({
	addTagTypes: ['ExternalAgencyShifts']
});

const externalAgencyShiftsAPI = apiWithTags.injectEndpoints({
	tagTypes: ['ExternalAgencyShifts'],
	endpoints: (build) => ({
		externalAgencyOpenShifts: build.query({
			query: ({ start, end }) => `/agencies/shifts?start=${start}&end=${end}`,
			providesTags: ['ExternalAgencyShifts']
		}),
		externalAgencyAssignedShifts: build.query({
			query: () => `/agencies/assigned-shifts`,
			providesTags: ['ExternalAgencyShifts']
		}),
		assignExternalProfessional: build.mutation({
			query: ({ shiftId, caregiverId, shiftData, recurringShiftData }) => ({
				url: `/agencies/shifts/${shiftId}/assign-professional/${caregiverId}`,
				method: 'POST',
				body: { shiftData, recurringShiftData }
			}),
			providesTags: ['ExternalAgencyShifts']
		})
	})
});

export const {
	useExternalAgencyOpenShiftsQuery,
	useAssignExternalProfessionalMutation,
	useExternalAgencyAssignedShiftsQuery
} = externalAgencyShiftsAPI;

export default externalAgencyShiftsAPI;
