import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CaregiverSearch from '../../../components/CaregiverSearch/CaregiverSearch';
import { user } from '../../../redux/user';
import FilterSelects from './FilterSelects';
import FilterUtils from '../../../utils/FilterUtils';

import { SHIFT_FEATURES, SHIFT_STATUS } from '../../../constants/ShiftStatus';
import FiltersContainer from './FiltersContainer';

const convertUnitsToOptions = (units) => [
	{ id: 'unassigned', label: 'Unassigned', value: 'unassigned', checked: true },
	...units.map((u) => ({ id: u.id, label: u.unit_name, value: u.id, checked: true }))
];

const covertArrayStringToOptions = (structures) =>
	structures.map((e) => ({ id: e, label: e, value: e, checked: true }));

/**
 * Component for filtering shifts.
 *
 * @component
 * @param {object} props - The component props.
 * @param {object} props.filters - The current filters.
 * @param {Function} props.setFilters - Function to set filters.
 * @param {object} props.defaultFiltersState - The default state for filters.
 * @param {Array} props.caregivers - Array of assigned caregivers.
 * @param {Array} props.shiftStructures - Array of shift structures.
 * @returns {JSX.Element} The rendered component.
 */
const FilterShifts = ({ filters, setFilters, caregivers = [], shiftStructures = [], availableFilters }) => {
	const { activeFacility } = useSelector(user);
	const { units = [] } = activeFacility || {};
	const shiftStatusStructure = Object.values(SHIFT_STATUS);
	const shiftFeatureStructure = Object.values(SHIFT_FEATURES);
	const DEFAULT_ACTIVE_OPTIONS = {
		resourceType: FilterUtils.convertResourceTypesToOptions(shiftStructures),
		unit: convertUnitsToOptions(units),
		shiftStatus: covertArrayStringToOptions(shiftStatusStructure),
		shiftFeature: covertArrayStringToOptions(shiftFeatureStructure)
	};

	const [filterCount, setFilterCount] = useState(0);
	const [activeOptions, setActiveOptions] = useState(DEFAULT_ACTIVE_OPTIONS);
	const [selectedCaregiver, setSelectedCaregiver] = useState(null);
	const [isCaregiverSelectOpen, setIsCaregiverSelectOpen] = useState(false);

	const onDateChange = (options) => setFilters((prevFilters) => ({ ...prevFilters, date: [options] }));
	const onCaregiverSelect = (options) => setFilters((prevFilters) => ({ ...prevFilters, caregiver: options || [] }));

	const removeSelectedCaregiver = () => {
		onCaregiverSelect(null);
		setSelectedCaregiver(null);
		setIsCaregiverSelectOpen(false);
	};

	useEffect(() => {
		if (shiftStructures.length !== activeOptions?.resourceType?.length) {
			setActiveOptions({ ...activeOptions, resourceType: FilterUtils.convertResourceTypesToOptions(shiftStructures) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shiftStructures]);

	useEffect(() => {
		if (units.length !== activeOptions?.unit?.length) {
			setActiveOptions({ ...activeOptions, unit: convertUnitsToOptions(units) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [units]);

	useEffect(() => {
		if (filterCount === 0) {
			setActiveOptions(DEFAULT_ACTIVE_OPTIONS);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterCount]);

	useEffect(() => {
		// Calculate the count of applied filters by iterating through keys of the 'filters' object

		setFilterCount(
			Object.keys(filters)
				.filter((item) => item !== 'caregiver')
				.reduce((count, key) => {
					// Retrieve the values for the current filter and active options
					const filterValues = filters[key] || [];
					const activeValues = activeOptions[key] || [];

					// Increment the count if a filter has values and those values are not equal to the active options,
					// indicating that the filter has been applied
					return (
						count +
						(filterValues.length && filterValues.length !== 0 && filterValues.length !== activeValues.length ? 1 : 0)
					);
				}, 0)
		);
	}, [filters, activeOptions]);

	const handleMultiSelectChange = (_options, feature) => {
		// here, we want to get the selected options in a flat array, by getting the ones that are checked
		// and then getting its value. After that, we use the array lenth to validate if all the elements
		// are selected or not.
		const selected = _options.filter((option) => option.checked).map((option) => option.value);
		const isSelectAll = _options.length === selected.length;

		// we update dynamically the options array, updating the option that matches with the feature name,
		// and then copying the rest of the elements in the object
		const updatedOptions = Object.fromEntries(
			Object.entries(activeOptions).map((option) => {
				if (option[0] === feature) {
					return [feature, _options];
				}
				return option;
			})
		);

		const _selected = isSelectAll ? undefined : selected;

		// we update dynamically the filters array, updating the filter that matches with the feature name,
		// and then copying the rest of the elements in the array
		const updatedFilter = Object.fromEntries(
			Object.entries(filters).map(([key, value]) => {
				return key === feature ? [key, _selected] : [key, value];
			})
		);

		// Update the state with the new options and filters
		setActiveOptions(updatedOptions);
		setFilters(updatedFilter);
	};

	const handleClearFilters = () => {
		setFilters(
			Object.fromEntries(
				Object.entries(filters).map(([key, value]) => {
					return key !== 'caregiver' ? [key, ''] : [key, value];
				})
			)
		);
		setActiveOptions(DEFAULT_ACTIVE_OPTIONS);
	};

	const filterCol = (
		<div className="col-xl-8 col-md-8">
			<FilterSelects
				onMultiSelectChange={handleMultiSelectChange}
				activeOptions={activeOptions}
				dateFilter={filters.date?.[0] ?? ''}
				onDateChange={onDateChange}
				availableFilters={availableFilters}
			/>
		</div>
	);

	const filterSearchCaregiver = (
		<CaregiverSearch
			caregivers={caregivers}
			onCaregiverSelect={onCaregiverSelect}
			selectedCaregiver={selectedCaregiver}
			setSelectedCaregiver={setSelectedCaregiver}
			isOpen={isCaregiverSelectOpen}
			setIsOpen={setIsCaregiverSelectOpen}
			removeSelectedCaregiver={removeSelectedCaregiver}
		/>
	);

	return (
		<FiltersContainer
			filterCount={filterCount}
			handleClearFilters={handleClearFilters}
			filterCol={filterCol}
			filterSearchCaregiver={filterSearchCaregiver}
		/>
	);
};

export default memo(FilterShifts);
