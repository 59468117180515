import api from './api';

const apiWithTags = api.enhanceEndpoints({
	addTagTypes: ['AgencyProfessionals', 'AgencyProfessional']
});

const agencyProfessionalsApi = apiWithTags.injectEndpoints({
	tagTypes: ['AgencyProfessionals', 'AgencyProfessional'],
	endpoints: (build) => ({
		agencyProfessionals: build.query({
			query: () => '/agencies/professionals',
			providesTags: ['AgencyProfessionals']
		}),
		agencyProfessional: build.query({
			query: (caregiverId) => `/agencies/professionals/${caregiverId}`,
			providesTags: ['AgencyProfessional']
		}),
		updateAgencyProfessional: build.mutation({
			query: ({ caregiverId, body }) => ({
				url: `/agencies/professionals/${caregiverId}`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['AgencyProfessional', 'AgencyProfessionals']
		})
	})
});

export const {
	useAgencyProfessionalsQuery,
	useAgencyProfessionalQuery,
	useUpdateAgencyProfessionalMutation
} = agencyProfessionalsApi;

export default agencyProfessionalsApi;
