import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputGroup, Button } from 'react-bootstrap';
import classNames from 'classnames';
import SelectedCaregiver from './SelectedCaregiver';
import './CaregiverSearch.scss';
import useOnClickOutside from '../hooks/useOnClickOutside';
import CaregiversList from './CaregiversList';

/**
 * CaregiverSearch Component
 *
 * This component provides a search functionality for caregivers.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object[]} props.caregivers - An array of objects representing caregivers.
 * @param {string} props.caregivers[].caregiverName - The name of the caregiver.
 * @param {string} props.caregivers[].certificationType - The type of certification the caregiver has.
 * @param {Object} props.caregivers[].caregiverStats - Statistics related to the caregiver.
 * @param {string} props.caregivers[].caregiverId - The unique identifier for the caregiver.
 * @param {Function} props.onCaregiverSelect - Callback function triggered when a caregiver is selected.
 * @param {Function} props.removeSelectedCaregiver - Callback function triggered when a selected caregiver is removed.
 * @param {Object} props.selectedCaregiver - The currently selected caregiver object.
 * @param {Function} props.setSelectedCaregiver - Callback function to set the selected caregiver.
 * @param {boolean} props.isOpen - A boolean indicating whether the caregiver search is open.
 * @param {Function} props.setIsOpen - Callback function to set the open state of the caregiver search.
 * @param {string} props.placeholder - placeholder string for the search area.
 * @param {string} props.emptyListPlaceholder - placeholder string for when the caregivers list is empty.
 *
 * @returns {JSX.Element} The rendered CaregiverSearch component.
 */
const CaregiverSearch = ({
	caregivers = [],
	onCaregiverSelect = () => {},
	removeSelectedCaregiver = () => {},
	selectedCaregiver,
	setSelectedCaregiver = () => {},
	isOpen,
	setIsOpen = () => {},
	placeholder = 'Filter by Professional',
	emptyListPlaceholder = 'No shifts for this professional',
	className
}) => {
	const containerRef = useRef(null);
	const [searchValue, setSearchValue] = useState('');
	const [filterCaregivers, setFilterCaregivers] = useState([]);

	const searchCaregivers = (e) => {
		const { value } = e.target;
		setSearchValue(value);
		const _filteredCaregivers = caregivers.filter((caregiver) =>
			caregiver.caregiverName.toLowerCase().includes(value.toLowerCase())
		);
		setFilterCaregivers(_filteredCaregivers);
	};

	const addSelectedCaregiver = (caregiver) => {
		onCaregiverSelect([caregiver.caregiverId]);
		setSelectedCaregiver(caregiver);
		setSearchValue('');
		setFilterCaregivers([]);
		setIsOpen(false);
	};

	useOnClickOutside(containerRef, () => setIsOpen(false), 'mousedown');

	useEffect(() => {
		if (setIsOpen && selectedCaregiver === null) {
			setIsOpen(false);
		}
	}, [selectedCaregiver, setIsOpen]);

	return (
		<>
			<section className={classNames('search-caregivers-container', { active: isOpen }, className)} ref={containerRef}>
				<InputGroup className="search-caregivers" onClick={() => setIsOpen(!isOpen)}>
					<InputGroup.Text className="search-icon" />
					{selectedCaregiver && selectedCaregiver.caregiverId?.length > 0 ? (
						<SelectedCaregiver caregiver={selectedCaregiver} onClear={removeSelectedCaregiver} />
					) : (
						<>
							<FormControl
								placeholder={placeholder}
								aria-label={placeholder}
								aria-describedby="clear-button"
								value={searchValue}
								onChange={searchCaregivers}
							/>
							{searchValue.length > 0 && (
								<Button className="clear-button" id="clear-button" onClick={() => setSearchValue('')}>
									Clear
								</Button>
							)}
						</>
					)}
				</InputGroup>
				<CaregiversList
					searchValue={searchValue}
					caregivers={filterCaregivers}
					isOpen={isOpen}
					onSelect={addSelectedCaregiver}
					placeholder={emptyListPlaceholder}
				/>
			</section>
		</>
	);
};

export default CaregiverSearch;
