import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { PRIVATE_PATH } from '../../constants';

import AgencyProfessionalsEmployeeDetails from './AgencyProfessionalsEmployeeDetails';

import SidebarWrapper from '../../sections/SidebarWrapper';
import { PencilIcon } from '../../components/icons';
import AgencyProfessionalsEditEmployee from './AgencyProfessionalsEditEmployee';
import './AgencyProfessionalsDetails.scss';

const AgencyProfessionalsDetails = (props) => {
	const [editMode, setEditMode] = useState(false);
	const [showEditIcon, setShowEditIcon] = useState(false);
	const history = useHistory();
	const professionalId = props.match.params.caregiverId;

	const sidebarItems = [
		{
			label: 'Employee Details',
			editTitle: 'Edit Details',
			isEditing: editMode,
			path: `${PRIVATE_PATH.PROFESSIONALS_EMPLOYEE_DETAILS}/${professionalId}`,
			additionalAction: showEditIcon && !editMode && (
				<button className="edit-contact" onClick={() => setEditMode(true)} type="button">
					<PencilIcon />
					Edit
				</button>
			),
			editText: 'Edit Details'
		}
	];

	const handleBackToProfessionalsList = () => {
		history.push('/private/agency/professionals');
	};

	return (
		<SidebarWrapper
			sidebarTitle="Manage Professional"
			sidebarItems={sidebarItems}
			backTitle="Back To Professionals"
			backAction={handleBackToProfessionalsList}
		>
			{editMode ? (
				<AgencyProfessionalsEditEmployee setEditMode={setEditMode} caregiverId={professionalId} />
			) : (
				<AgencyProfessionalsEmployeeDetails setShowEditIcon={setShowEditIcon} caregiverId={professionalId} />
			)}
		</SidebarWrapper>
	);
};

export default AgencyProfessionalsDetails;
