/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import FilterUtils from '../utils/FilterUtils';
import api from './api';
import { GLOBAL_OVERVIEW_SHIFT_STATUS } from '../constants/ShiftStatus';

const globalFiltersInitialState = {
	resourceTypes: [],
	statuses: FilterUtils.convertGlobalOverviewShiftStatusToOptions(GLOBAL_OVERVIEW_SHIFT_STATUS),
	selectedCaregiverFilter: null
};

const apiWithTags = api.enhanceEndpoints({
	addTagTypes: ['GlobalOverviewShifts', 'GlobalOverviewShiftStructures']
});

const globalOverviewAPI = apiWithTags.injectEndpoints({
	tagTypes: ['GlobalOverviewShifts', 'GlobalOverviewShiftStructures'],
	endpoints: (build) => ({
		shifts: build.query({
			query: ({ start, end }) => `/flex/global-overview/shifts?start=${start}&end=${end}`,
			providesTags: ['GlobalOverviewShifts']
		}),
		shiftStructures: build.query({
			query: () => `/flex/global-overview/shift-structures`,
			providesTags: ['GlobalOverviewShifts']
		}),
		reopenShift: build.mutation({
			query: ({ slot }) => ({
				url: `/flex/slot/${slot.slot_id}/reopen`,
				method: 'PUT',
				headers: {
					management_company_id: slot.management_company_id
				},
				responseHandler: async (response) => {
					const responseText = await response.text();
					return responseText === 'OK' ? responseText : JSON.parse(responseText);
				}
			}),
			invalidatesTags: ['GlobalOverviewShifts']
		})
	})
});

export const { useShiftsQuery, useReopenShiftMutation, useShiftStructuresQuery } = globalOverviewAPI;

export const slice = createSlice({
	name: 'globalOverview',
	initialState: {
		periodIndex: 0,
		selectedFacilities: [],
		globalFilters: globalFiltersInitialState
	},
	reducers: {
		savePeriodIndex: (state, action) => {
			state.periodIndex = action.payload;
		},
		saveSelectedFacilities: (state, action) => {
			state.selectedFacilities = action.payload;
		},
		saveFilters: (state, action) => {
			state.globalFilters = action.payload;
		}
	}
});

export const globalOverviewState = (state) => state.globalOverview;

export const { savePeriodIndex, saveSelectedFacilities, saveFilters } = slice.actions;

export default slice.reducer;
