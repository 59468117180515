import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import Avatar from '../../Avatar/CaregiverAvatar';
import { TIER } from '../../../constants';
import platinumBadge from '../../../assets/images/caregiver-platinum-badge.svg';
import RemoveIcon from '../../icons/RemoveIcon';
import Utils from '../../../utils/utils';

import './CaregiverSearchControl.scss';

const CaregiverSearchControl = ({
	searchedCaregivers,
	errorSearch,
	searchValue,
	searchTermChange,
	selectedCaregivers,
	addSelectedCaregiver,
	removeSelectedProfessional,
	loading,
	copy,
	searchDisabled = false,
	searchSectionTitle = null,
	selectedSectionTitle = null,
	showSelectedProfessionals = true,
	inputPlaceholder = null,
	allowSingleCaregiverSelection = false
}) => {
	return (
		<div className="section-caregiver-search">
			<section className="search-professionals-container">
				<div className="search-professionals-header">
					<h4>
						<span>{searchSectionTitle || 'Search and Select Professional(s)'}</span>
					</h4>
				</div>
				<InputGroup className="search-invite">
					<InputGroup.Text className="search-icon" />
					<FormControl
						disabled={searchDisabled}
						placeholder={inputPlaceholder || 'Search for Professional'}
						aria-label="Search"
						aria-describedby="search professional"
						value={searchValue}
						onChange={searchTermChange}
					/>
				</InputGroup>
				{searchedCaregivers && searchedCaregivers.length > 0 && !errorSearch && searchValue !== '' && (
					<div className="searched-caregivers">
						<ul className="list-search-caregivers">
							{searchedCaregivers.map((caregiver) => {
								const shiftsWorked = caregiver.number_of_shift_worked
									? `${Utils.pluralize(
											`${caregiver.number_of_shift_worked} shift`,
											's',
											caregiver.number_of_shift_worked
									  )} worked at this facility`
									: '';
								return (
									<li key={`caregiver-${caregiver.caregiver_id}`}>
										<button
											type="button"
											onClick={() => addSelectedCaregiver(caregiver, allowSingleCaregiverSelection)}
										>
											<Avatar caregiverId={caregiver.caregiver_id} />
											<span>
												{caregiver.caregiver_name}
												{caregiver.certification_type ? `, ${caregiver.certification_type}` : ''}
												{shiftsWorked ? `, ${shiftsWorked}` : ''}
											</span>
											{caregiver.caregiver_stats?.tier === TIER.PLATINUM ? (
												<img
													key="tier-badge"
													className="platinum-badge-caregiver logo-mobile"
													alt="Alice Denver"
													src={platinumBadge}
												/>
											) : null}
										</button>
									</li>
								);
							})}
						</ul>
					</div>
				)}
				{searchValue !== '' && !errorSearch && searchedCaregivers.length === 0 && (
					<div className="searched-caregivers">
						<ul className="list-search-caregivers">
							<li>
								<button type="button">{loading ? <span>Loading...</span> : <span>No Professional found.</span>}</button>
							</li>
						</ul>
					</div>
				)}
				{searchValue === '' && searchedCaregivers.length === 0 && !errorSearch && copy !== false && (
					<div className="search-professional-info-container">
						<span className="info-icon" />
						<p>
							{copy ||
								`Search for a returning professional with this license type to invite to this shift. Inviting a
							professional does not create additional shifts.`}
						</p>
					</div>
				)}
				{errorSearch && (
					<div className="search-professional-info-container">
						<span className="info-icon" />
						<p> Something went wrong and your search couldn&apos;t be completed</p>
					</div>
				)}
			</section>
			<br />
			<br />
			{showSelectedProfessionals && (
				<section className="selected-professionals-container">
					<div className="selected-professionals-header">
						<h4>
							<span>{selectedSectionTitle || `Selected Professional(s) – ${selectedCaregivers.length}`}</span>
						</h4>
					</div>
					<div className="selected-professionals-body">
						{selectedCaregivers.length > 0 ? (
							<ul className="list-selected-caregivers">
								{selectedCaregivers.map((caregiver) => {
									const shiftsWorked = caregiver.number_of_shift_worked
										? `${Utils.pluralize(
												`${caregiver.number_of_shift_worked} shift`,
												's',
												caregiver.number_of_shift_worked
										  )} worked at this facility`
										: '';
									return (
										<div key={`selected-caregiver-${caregiver.caregiver_id}`} className="selected-caregiver-container">
											<li>
												<Avatar caregiverId={caregiver.caregiver_id} />
												<span>
													{caregiver.caregiver_name}
													{caregiver.certification_type ? `, ${caregiver.certification_type}` : ''}
													{shiftsWorked ? `, ${shiftsWorked}` : ''}
												</span>
												{caregiver.caregiver_stats?.tier === TIER.PLATINUM ? (
													<img
														key="tier-badge"
														className="platinum-badge-caregiver logo-mobile"
														alt="Alice Denver"
														src={platinumBadge}
													/>
												) : null}
											</li>
											<button type="button" onClick={(evt) => removeSelectedProfessional(evt, caregiver)}>
												<RemoveIcon
													className="remove-selected-professional"
													alt="Remove selected professional"
													aria-hidden
												/>
											</button>
										</div>
									);
								})}
							</ul>
						) : null}
					</div>
				</section>
			)}
		</div>
	);
};

export default CaregiverSearchControl;
