/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment-timezone';
import { showNotification } from '../../redux/notification';
import refreshIcon from '../../assets/images/refresh-purple.svg';
import DateRangeSelector from '../../components/range-selector/DateRangeSelector';
import AgencyAssignModal from './assign-professionals/AgencyAssignModal';
import { useExternalAgencyOpenShiftsQuery } from '../../redux/externalAgencyShifts';
import { applyFilters } from '../../utils/FilterFunctions';
import FiltersSection from './FiltersSection';
import ShiftsCountBanner from './ShiftsCountBanner';
import {
	shiftTypeTemplate,
	rateTemplate,
	estimatedPayTemplate,
	startDateTemplate,
	timePeriodTemplate,
	facilityTemplate,
	buildFilterOptions
} from './agency-utils';

const AgencyOpenShifts = () => {
	const DEFAULT_FILTER_OPTIONS = {
		facilities: [],
		shiftTypes: [],
		startTimes: []
	};
	const timer = useRef(null);
	const dispatch = useDispatch();
	const [selectedRow, setSelectedRow] = useState(null);
	const [filterOptions, setFilterOptions] = useState(DEFAULT_FILTER_OPTIONS);
	const [filters, setFilters] = useState(DEFAULT_FILTER_OPTIONS);
	const [rangeStart, setRangeStart] = useState(new Date());
	const [rangeEnd, setRangeEnd] = useState(moment(rangeStart).add(13, 'day').toDate());
	const [shifts, setShifts] = useState([]);
	const [showAssignProfessionalModal, setShowAssignProfessional] = useState(null);
	const [shiftToBeAssigned, setShiftToBeAssigned] = useState(null);
	const [assignedProfessionalAnimation, setAssignedProfessionalAnimation] = useState(false);

	const { data: openShifts, refetch: reloadOpenShifts } = useExternalAgencyOpenShiftsQuery(
		{ start: moment(rangeStart).format('YYYY-MM-DD'), end: moment(rangeEnd).format('YYYY-MM-DD') },
		{ refetchOnMountOrArgChange: true }
	);

	useEffect(() => {
		setRangeEnd(moment(rangeStart).add(13, 'day'));

		return () => clearTimeout(timer.current);
	}, []);

	const handleAssignProfessionalModal = (shift) => {
		setShiftToBeAssigned(shift);
		setShowAssignProfessional(true);
		setSelectedRow(shift);
	};

	useEffect(() => {
		if (openShifts) {
			const options = buildFilterOptions(openShifts, filters);
			setFilterOptions(options);
		}
	}, [openShifts]);

	useEffect(() => {
		const filteredShifts = applyFilters({ items: openShifts || [], filters });
		setShifts(filteredShifts);
	}, [openShifts, filters]);

	const updateFilters = (updatedOptions, updatedFilters) => {
		setFilterOptions(updatedOptions);
		setFilters(updatedFilters);
	};

	const hasFiltersApplied = filters.facilities?.length || filters.shiftTypes?.length || !!filters.startTimes?.length;

	const professionalTemplate = (item) =>
		assignedProfessionalAnimation && !shiftToBeAssigned.error && shiftToBeAssigned.shift_id === item.shift_id ? (
			<div className="professional-name">{shiftToBeAssigned.caregiverName}</div>
		) : (
			<div className="assign-button" onClick={() => handleAssignProfessionalModal(item)}>
				+ Assign
			</div>
		);

	const rowClass = (rowData) => {
		if (assignedProfessionalAnimation && rowData.shift_id === shiftToBeAssigned.shift_id) {
			if (shiftToBeAssigned.error) {
				if (shiftToBeAssigned.errorField === 'available_slot') {
					return 'remove-row-animation';
				}
				return 'highlight-error-row';
			}
			if (Number(shiftToBeAssigned.slots_count) === 1) {
				return 'highlight-assigned-row delayed-remove-row-animation';
			}
			return 'highlight-assigned-row';
		}
		return '';
	};

	const _showNotification = (type, message) => dispatch(showNotification(type, message));

	const removeShift = (_shift) => {
		const newArray = shifts.filter((item) => item.shift_id !== _shift.shift_id);
		setShifts(newArray);
	};

	const updateShift = (_shift) => {
		const newArray = shifts.map((item) => {
			if (item.shift_id === _shift.shift_id) {
				return { ...item, slots_count: Number(item.slots_count) - 1 };
			}
			return item;
		});
		setShifts(newArray);
	};

	const onAssignCompleted = (_shift) => {
		setShowAssignProfessional(false);
		setAssignedProfessionalAnimation(true);
		setShiftToBeAssigned(_shift);

		let timeout = 2200;
		let shouldRemoveShift = false;
		if (_shift.errorField === 'available_slot') {
			timeout = 1000;
			shouldRemoveShift = true;
		} else if (!_shift.error && Number(_shift.slots_count) === 1) {
			timeout = 3200;
			shouldRemoveShift = true;
		}

		timer.current = setTimeout(() => {
			setAssignedProfessionalAnimation(false);
			if (shouldRemoveShift) {
				removeShift(_shift);
			} else if (!_shift.error) {
				updateShift(_shift);
			}
			reloadOpenShifts();
		}, timeout);
	};

	return (
		<>
			<div className="toolbar">
				<DateRangeSelector
					rangeStart={rangeStart}
					rangeEnd={rangeEnd}
					setRangeStart={setRangeStart}
					setRangeEnd={setRangeEnd}
					minDate={new Date()}
					proTip="Try selecting a date range that is within 2 weeks of the start and end date. If a range is too big it will only show the first 2000 shifts from the start date to ensure a smoother experience."
				/>

				<button type="button" className="btn refresh-button" onClick={reloadOpenShifts}>
					<img src={refreshIcon} alt="Refresh" />
					<div className="button-label">Refresh Feed</div>
				</button>
				<div className="flex-one" />
				<FiltersSection filterOptions={filterOptions} filters={filters} updateFilters={updateFilters} />
			</div>
			<div className="datatable-wrapper">
				<div className="wrapper-container">
					<DataTable
						value={shifts}
						scrollable
						scrollHeight="flex"
						sortMode="multiple"
						selectionMode="single"
						selection={selectedRow}
						dataKey="shift_id"
						virtualScrollerOptions={{ itemSize: 65 }}
						tableStyle={{ minWidth: '100rem' }}
						size="small"
						stripedRows
						cellClassName="table-cell"
						rowClassName={rowClass}
						footer={
							shifts?.length ? (
								<ShiftsCountBanner shiftsCount={shifts.length} filtersApplied={hasFiltersApplied} />
							) : null
						}
					>
						<Column field="start_time" header="Date" style={{ width: '12%' }} sortable body={startDateTemplate} />
						<Column field="shift_type" header="Shift type" style={{ width: '10%' }} body={shiftTypeTemplate} />
						<Column field="slots_count" header="Shifts" style={{ width: '5%' }} sortable />
						<Column field="facility_name" header="Facility" style={{ width: '15%' }} sortable body={facilityTemplate} />
						<Column field="start_hour_tz" header="Time" style={{ width: '14%' }} body={timePeriodTemplate} />
						<Column
							field="estimated_pay"
							header="Est. pay"
							style={{ width: '6%' }}
							sortable
							body={estimatedPayTemplate}
						/>
						<Column field="rate" header="Rate" style={{ width: '6%' }} sortable body={rateTemplate} />
						<Column field="facility_city" header="City" style={{ width: '10%' }} sortable />
						<Column field="facility_state_code" header="State" style={{ width: '5%' }} sortable />
						<Column field="facility_zip_code" header="ZIP" style={{ width: '5%' }} sortable />
						<Column
							field="professional"
							header="Professional"
							style={{ width: '12%' }}
							alignFrozen="right"
							frozen
							body={professionalTemplate}
						/>
					</DataTable>

					{showAssignProfessionalModal && (
						<AgencyAssignModal
							shift={shiftToBeAssigned}
							onCancel={() => setShowAssignProfessional(false)}
							onComplete={onAssignCompleted}
							showNotification={_showNotification}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default AgencyOpenShifts;
