/* eslint-disable */
import React, { useState, memo, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { produce } from 'immer';
import searchIcon from '../../assets/images/search-icon.svg';
import closeIcon from '../../assets/images/close-black.svg';
import businessIcon from '../../assets/images/business.svg';
import CheckBoxSection from '../../components/MultiSelectSearch/CheckBoxSection';

import './LeftSidebar.scss';

/**
 * LeftSidebar
 *
 * @param {Object} props
 * @param {Object[]} props.availableFacilities - Array of objects with facility data
 * @param {Object[]} props.selectedFacilities - Array of objects with facility data
 * @param {Function} props.onApply - Callback function to apply the selected options
 *
 * @returns {JSX.Element}
 */
const LeftSidebar = (props) => {
	const [facilitySearchTerm, setFacilitySearchTerm] = useState('');
	const [persistedSearchTerm, setPersistedSearchTerm] = useState('');
	const [facilityOptions, setFacilityOptions] = useState([]);
	const [hasChanges, setHasChanges] = useState(false);

	const initFacilityList = () => {
		if (props.availableFacilities.length === props.selectedFacilities.length) {
			setFacilityOptions(props.selectedFacilities);
		} else {
			setFacilityOptions(
				props.availableFacilities.map((item) => {
					const _checked = !!props.selectedFacilities.find((sf) => sf.id === item.id);
					return { ...item, checked: _checked };
				})
			);
		}
	};

	useEffect(() => {
		if (props.availableFacilities.length) {
			initFacilityList();
		}
	}, [props.availableFacilities]);

	const visibleOptions = useMemo(() => {
		const searchTerm = facilitySearchTerm?.toLowerCase();
		return facilityOptions.filter((item) => item.label?.toLowerCase().includes(searchTerm));
	}, [facilityOptions, facilitySearchTerm]);
	const checkedOptions = useMemo(() => facilityOptions.filter((item) => item.checked), [facilityOptions]);

	const updateSearchTerm = (term) => {
		setFacilitySearchTerm(term);
		setHasChanges(true);
	};

	const handleSelectAll = () => {
		const _facilityOptions = facilityOptions.map((option) => {
			if (facilitySearchTerm && !option.label?.toLowerCase().includes(facilitySearchTerm.toLowerCase())) {
				return option;
			}
			return {
				...option,
				checked: !_selectAll
			};
		});
		setFacilityOptions(_facilityOptions);
		setHasChanges(true);
	};

	const handleCheckboxChange = (id) => {
		const nextState = produce(facilityOptions, (draft) => {
			const item = draft.find((i) => i.id === id);
			item.checked = !item.checked;
		});
		setHasChanges(true);
		setFacilityOptions(nextState);
	};

	const handleReset = () => {
		setFacilitySearchTerm('');
		setFacilityOptions(props.availableFacilities.map((item) => ({ ...item, checked: true })));
		setHasChanges(true);
	};

	const handleCancel = () => {
		initFacilityList();
		setFacilitySearchTerm(persistedSearchTerm);
		setHasChanges(false);
	};

	const handleApply = () => {
		setPersistedSearchTerm(facilitySearchTerm);
		setHasChanges(false);
		props.onApply([...checkedOptions]);
	};

	const _selectAll = !visibleOptions.find((item) => !item.checked);

	return (
		<div className="overview-sidebar">
			<div className="heading">
				<img src={businessIcon} alt="facilities" className="facilities-icon" />
				<span>Facilities</span>
			</div>
			<div className="search-container">
				<img src={searchIcon} alt="search" className="search-icon" />
				<input
					type="text"
					className="search-input"
					maxLength={30}
					placeholder="Search"
					value={facilitySearchTerm}
					onChange={(e) => updateSearchTerm(e.target.value)}
				/>
				{!!facilitySearchTerm.length && (
					<img src={closeIcon} alt="delete" className="delete-icon" onClick={() => updateSearchTerm('')} />
				)}
			</div>
			<div className="selected-facility-heading">Selected Facilities ({checkedOptions.length})</div>
			<div className="checkbox-section">
				<CheckBoxSection
					id="global-overview-left-side"
					selectAllOption={_selectAll}
					onSelectAll={handleSelectAll}
					checkboxOptions={visibleOptions}
					onCheckboxChange={handleCheckboxChange}
					noResultsMessage="No results found"
					search={visibleOptions.length !== props.availableFacilities.length}
				/>
			</div>
			{(checkedOptions.length !== props.availableFacilities.length || facilitySearchTerm) && (
				<div className="reset-container">
					<button type="reset" className="reset-button" onClick={handleReset}>
						Reset
					</button>
				</div>
			)}
			<div className="buttons-container">
				<button
					type="button"
					className={classNames('button secondary-button', { disabled: !hasChanges })}
					onClick={handleCancel}
					disabled={!hasChanges}
				>
					Cancel
				</button>
				<button
					type="button"
					className={classNames('button primary-button', { disabled: !hasChanges })}
					onClick={handleApply}
					disabled={!hasChanges}
				>
					Apply
				</button>
			</div>
		</div>
	);
};

export default memo(LeftSidebar);
