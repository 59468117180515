import React from 'react';
import './NoMatchingShifts.scss';

const NoMatchingShifts = ({ reason }) => {
	const {
		labels: { title, subtitle },
		labelBtn,
		actionBtn
	} = reason;

	return (
		<div className="no-matching-shifts__container">
			<div className="no-matching-shifts__title">{title}</div>
			{subtitle && <div className="no-matching-shifts__subtitle">{subtitle}</div>}
			{labelBtn && (
				<button onClick={actionBtn} className="no-matching-shifts__clear-button" type="button">
					{labelBtn}
				</button>
			)}
		</div>
	);
};

export default NoMatchingShifts;
