import React, { useState, memo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useAssignExternalProfessionalMutation } from '../../../redux/externalAgencyShifts';
import { startDateTemplate } from '../agency-utils';
import AgencyAssign from './AgencyAssign';

import './AgencyAssignModal.css';

const AgencyAssignModal = ({ shift = {}, onCancel = () => {}, onComplete, showNotification }) => {
	const [assignExternalProfessional] = useAssignExternalProfessionalMutation();
	const [selectedCaregivers, setSelectedCaregivers] = useState([]);
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

	const addCaregiver = (caregiver, clearSelectedCaregivers) => {
		if (clearSelectedCaregivers) {
			setSelectedCaregivers([caregiver]);
			return;
		}

		const found = selectedCaregivers.find((c) => c.caregiver_id === caregiver.caregiver_id);
		if (!found) {
			setSelectedCaregivers([...selectedCaregivers, ...[caregiver]]);
		}
	};

	const removeCaregiver = (caregiver) => {
		const newSelectedCaregiver = selectedCaregivers.filter((c) => c !== caregiver);
		setSelectedCaregivers(newSelectedCaregiver);
	};
	const shiftId = shift.is_recurring ? shift.recurring_shift_id : shift.shift_id;
	const handleAssign = async () => {
		setShowLoadingSpinner(true);
		const [caregiver] = selectedCaregivers;
		try {
			await assignExternalProfessional({
				shiftId,
				caregiverId: caregiver.caregiver_id,
				shiftData: !shift.is_recurring
					? {
							isGuaranteed: shift.is_guaranteed
					  }
					: null,
				recurringShiftData: shift.is_recurring
					? {
							shiftId: shift.recurring_shift_id,
							shiftDate: moment.tz(shift.start_time, shift.facility_timezone).format('YYYY-MM-DD'),
							facilityId: shift.facility_id,
							isGuaranteed: shift.is_guaranteed
					  }
					: null
			}).unwrap();
			setTimeout(() => {
				showNotification('info', 'Assigned Professionals have been successfully moved to the "Assigned" tab.');
			}, 1600);
			onComplete({ ...shift, caregiverName: caregiver.caregiver_name });
		} catch (error) {
			const message =
				error.data?.errors?.[0]?.message || 'Sorry, there was an error trying to assign this shift. Please try again.';
			showNotification('error', message);
			onComplete({ ...shift, error: true, errorField: error.data?.errors?.[0]?.field });
		}
	};

	return (
		<Modal size="lg" show className={classNames('assign-professional-modal', 'no-gutters')} onHide={onCancel}>
			<Modal.Header closeButton>
				<Modal.Title id="assign-professional-modal-title">
					<b>
						Assign {shift.resource_type} {shift.specialty && ` - ${shift.specialty}`}
					</b>{' '}
					- <span>{startDateTemplate(shift)}</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<AgencyAssign
					shift={shift}
					removeCaregiver={removeCaregiver}
					addCaregiver={addCaregiver}
					selectedCaregivers={selectedCaregivers}
					showLoadingSpinner={showLoadingSpinner}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onCancel} variant="tertiary" disabled={false}>
					Cancel
				</Button>
				<Button
					variant="primary"
					disabled={selectedCaregivers.length === 0}
					className="no-gutters"
					onClick={handleAssign}
				>
					Assign professional
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default memo(AgencyAssignModal);
