/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputGroup, Button } from 'react-bootstrap';
import classNames from 'classnames';
import './ProfessionalSearch.scss';
import useOnClickOutside from '../hooks/useOnClickOutside';
import useSearchDebounce from '../../hooks/useSearchDebounce';
import ProfessionaList from './ProfessionalList';
import SelectedProfesional from './SelectedProfessional';

const mapCaregiverData = (data = []) => {
	return data.map((caregiver) => {
		const { caregiver_name, caregiver_id, caregiver_highest_ranked_licence } = caregiver;
		return {
			...caregiver,
			caregiverName: `${caregiver_name}, ${caregiver_highest_ranked_licence}`,
			resourceType: caregiver_highest_ranked_licence,
			caregiverId: caregiver_id
		};
	});
};

const MIN_CHARACTERS_ALLOWED = 3;

const ProfessionalSearch = ({
	removeSelectedCaregiver = () => {},
	selectedCaregiver,
	setSelectedCaregiver = () => {},
	isOpen,
	setIsOpen = () => {},
	placeholder = 'Filter by Professional',
	emptyListPlaceholder = 'No Shifts for this Professional',
	className,
	searchURL
}) => {
	const containerRef = useRef(null);
	const [searchValue, setSearchValue] = useState('');
	const [filterCaregivers, setFilterCaregivers] = useState([]);

	const handleResults = (results = []) => {
		setFilterCaregivers(mapCaregiverData(results));
	};

	const { searchTermChange } = useSearchDebounce(
		searchURL,
		searchValue,
		null,
		setSearchValue,
		() => {},
		() => {},
		handleResults,
		MIN_CHARACTERS_ALLOWED
	);

	const handleClearBtn = () => {
		setSearchValue('');
		setFilterCaregivers([]);
	};

	const addSelectedCaregiver = (caregiver) => {
		setSelectedCaregiver(caregiver);
		setSearchValue('');
		setFilterCaregivers([]);
		setIsOpen(false);
	};

	useOnClickOutside(containerRef, () => setIsOpen(false), 'mousedown');

	useEffect(() => {
		if (setIsOpen && selectedCaregiver === null) {
			setIsOpen(false);
		}
	}, [selectedCaregiver, setIsOpen]);

	useEffect(() => {
		if (!searchValue && filterCaregivers.length > 0) {
			setFilterCaregivers([]);
		}
	}, [searchValue, filterCaregivers]);

	return (
		<>
			<section
				className={classNames('search-professional-container', { active: isOpen }, className)}
				ref={containerRef}
			>
				<InputGroup className="search-professional" onClick={() => setIsOpen(!isOpen)}>
					<InputGroup.Text className="search-icon" />
					{selectedCaregiver && selectedCaregiver.caregiverId?.length > 0 ? (
						<SelectedProfesional caregiver={selectedCaregiver} onClear={removeSelectedCaregiver} />
					) : (
						<>
							<FormControl
								placeholder={placeholder}
								aria-label={placeholder}
								aria-describedby="clear-button"
								value={searchValue}
								onChange={searchTermChange}
							/>
							{searchValue.length > 0 && (
								<Button className="clear-button" id="clear-button" onClick={handleClearBtn}>
									Clear
								</Button>
							)}
						</>
					)}
				</InputGroup>
				{searchValue && searchValue.trim().length >= MIN_CHARACTERS_ALLOWED && (
					<ProfessionaList
						searchValue={searchValue}
						caregivers={filterCaregivers}
						isOpen={isOpen}
						onSelect={addSelectedCaregiver}
						placeholder={emptyListPlaceholder}
						searchMinChars={MIN_CHARACTERS_ALLOWED}
					/>
				)}
			</section>
		</>
	);
};

export default ProfessionalSearch;
