import React from 'react';
import moment from 'moment-timezone';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import utils from '../../utils/utils';
import SLOT_STATUSES from '../../constants/slotStatuses';
import guaranteedIcon from '../../assets/images/guaranteed-icon.svg';

export const shiftTypeTemplate = (item) => (
	<div className="d-flex shift-type">
		<div className="flex-one">
			{item.resource_type} {item.specialty && ` - ${item.specialty}`}
		</div>
		{item.is_guaranteed && <img className="guaranteed-badge" src={guaranteedIcon} alt="Guaranteed" />}
	</div>
);

export const estimatedPayTemplate = (item) => (
	<div>
		${item.estimated_pay} <br />
		(estimate)
	</div>
);

export const rateTemplate = (item) => <span>{utils.formatMoney(item.rate)}/hr</span>;

export const startDateTemplate = (item) => (
	<div>
		<span>{moment.tz(item.start_time, item.facility_timezone).format('dddd, MMM D, YYYY')}</span>
		{item.slot_status === SLOT_STATUSES.FACILITY_CANCELLED && <div className="cancelled-label">CANCELLED</div>}
	</div>
);

export const timePeriodTemplate = (item) => (
	<span className="time-period">
		{item.start_hour_tz} - {item.end_hour_tz} {moment.tz(item.start_time, item.facility_timezone).format('z')}
		<br />
		<span className="break-duration">({item.break_duration} min break)</span>
	</span>
);

export const facilityTemplate = (item) => (
	<OverlayTrigger
		trigger={['hover', 'focus']}
		key="facility-tooltip"
		placement="bottom"
		overlay={
			<Tooltip className="facility-menu-item-tooltip" placement="bottom" show>
				<span>{item.facility_address}</span>
				<br />
				<span>
					{item.facility_city}, {item.facility_state} {item.facility_zip_code}
				</span>
			</Tooltip>
		}
	>
		<div>{item.facility_name}</div>
	</OverlayTrigger>
);

export const buildFilterOptions = (shifts, filters) => {
	const addedFacilities = {};
	const addedTypes = {};
	const addedTimes = {};
	const _facilities = [];
	const _shiftTypes = [];
	const _startTimes = [];
	for (let i = 0; i < shifts.length; i++) {
		const {
			facility_id: facilityId,
			facility_name: facilityName,
			resource_type: resourceType,
			specialty,
			start_hour_tz: startHour
		} = shifts[i];
		if (!addedFacilities[facilityId]) {
			addedFacilities[facilityId] = 1;
			const _selected = !filters.facilities?.length || filters.facilities?.includes(facilityId);
			_facilities.push({ value: facilityId, label: facilityName, checked: _selected });
		}
		/* eslint-disable-next-line prefer-template */
		const shiftType = `${resourceType}${specialty ? ' - ' + specialty : ''}`;
		if (!addedTypes[shiftType]) {
			addedTypes[shiftType] = 1;
			const _selected = !filters.shiftTypes?.length || filters.shiftTypes?.includes(shiftType);
			_shiftTypes.push({ value: shiftType, label: shiftType, checked: _selected });
		}
		if (!addedTimes[startHour]) {
			addedTimes[startHour] = 1;
			const _selected = !filters.startTimes?.length || filters.startTimes?.includes(startHour);
			_startTimes.push({ value: startHour, label: startHour, checked: _selected });
		}
	}

	return {
		facilities: utils.sort(_facilities, 'value'),
		shiftTypes: utils.sort(_shiftTypes, 'value'),
		startTimes: utils.sortTimeStrings(_startTimes, 'value')
	};
};
