import React from 'react';
import utils from '../../../../utils/utils';
import { rateTemplate, shiftTypeTemplate, timePeriodTemplate } from '../../agency-utils';

import './ShiftDetails.scss';

const ShiftDetails = ({ shift }) => (
	<section className="shift-details-container modal-assign-professionals">
		<div className="shift-details-header">
			<h4>
				<span>Shift Details</span>
			</h4>
		</div>
		<div className="shift-details-body">
			<div className="shift-details-row">
				<div className="shift-details-row-div col-50">
					<p className="shift-details-title">Facility</p>
					<p className="shift-details-text">{shift.facility_name}</p>
				</div>
				<div className="shift-details-row-div col-50">
					<p className="shift-details-title">Shift type</p>
					<div className="shift-details-text">{shiftTypeTemplate(shift)}</div>
				</div>
			</div>
			<div className="shift-details-row">
				<div className="shift-details-row-div col-50">
					<p className="shift-details-title">Location</p>
					<div className="shift-details-text">
						{shift.facility_address} <br />
						{shift.facility_city}, {shift.facility_state_code} {shift.facility_zip_code}
					</div>
				</div>
				<div className="shift-details-row-div col-25">
					<p className="shift-details-title">Rate</p>
					<div className="shift-details-text">
						{rateTemplate(shift)}
						<br />
						<em>({utils.formatMoney(shift.estimated_pay)} estimate)</em>
					</div>
				</div>
				<div className="shift-details-row-div col-25">
					<p className="shift-details-title">Time</p>
					<div className="shift-details-text">{timePeriodTemplate(shift)}</div>
				</div>
			</div>
		</div>
	</section>
);

export default ShiftDetails;
