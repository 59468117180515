import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import refreshIcon from '../../assets/images/refresh-purple.svg';
import { useExternalAgencyAssignedShiftsQuery } from '../../redux/externalAgencyShifts';
import { applyFilters } from '../../utils/FilterFunctions';
import FiltersSection from './FiltersSection';
import SLOT_STATUSES from '../../constants/slotStatuses';
import {
	shiftTypeTemplate,
	rateTemplate,
	estimatedPayTemplate,
	startDateTemplate,
	timePeriodTemplate,
	facilityTemplate,
	buildFilterOptions
} from './agency-utils';

const AgencyAssignedShifts = ({ searchTerm }) => {
	const DEFAULT_FILTER_OPTIONS = {
		facilities: [],
		shiftTypes: [],
		startTimes: []
	};
	const [filterOptions, setFilterOptions] = useState(DEFAULT_FILTER_OPTIONS);
	const [filters, setFilters] = useState(DEFAULT_FILTER_OPTIONS);
	const [globalFilter, setGlobalFilter] = useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
	const [shifts, setShifts] = useState([]);

	const { data: assignedShifts, refetch: reloadAssignedShifts } = useExternalAgencyAssignedShiftsQuery(null, {
		refetchOnMountOrArgChange: true
	});

	useEffect(() => {
		if (assignedShifts) {
			const options = buildFilterOptions(assignedShifts, filters);
			setFilterOptions(options);
		}
	}, [assignedShifts]);

	useEffect(() => {
		const filteredShifts = applyFilters({ items: assignedShifts || [], filters });
		setShifts(filteredShifts);
	}, [assignedShifts, filters]);

	useEffect(() => {
		const _filter = { ...globalFilter };
		_filter.global.value = searchTerm;
		setGlobalFilter(_filter);
	}, [searchTerm]);

	const updateFilters = (updatedOptions, updatedFilters) => {
		setFilterOptions(updatedOptions);
		setFilters(updatedFilters);
	};

	const rowClass = (rowData) => (rowData.slot_status === SLOT_STATUSES.FACILITY_CANCELLED ? 'cancelled-shift-row' : '');

	const professionalTemplate = (item) => <span>{item.professional}</span>;
	const unitTemplate = (item) => <span>{item.unit}</span>;
	const cityTemplate = (item) => <span>{item.facility_city}</span>;
	const stateTemplate = (item) => <span>{item.facility_state_code}</span>;
	const zipTemplate = (item) => <span>{item.facility_zip_code}</span>;
	const idTemplate = (item) => <span>{item.id}</span>;

	return (
		<>
			<div className="toolbar">
				<button type="button" className="btn refresh-button" onClick={reloadAssignedShifts}>
					<img src={refreshIcon} alt="Refresh" />
					<div className="button-label">Refresh Feed</div>
				</button>
				<div className="flex-one" />
				<FiltersSection filterOptions={filterOptions} filters={filters} updateFilters={updateFilters} />
			</div>

			<div className="datatable-wrapper">
				<div className="wrapper-container">
					<DataTable
						value={shifts}
						scrollable
						scrollHeight="flex"
						sortMode="multiple"
						selectionMode="single"
						dataKey="id"
						virtualScrollerOptions={{ itemSize: 64 }}
						tableStyle={{ minWidth: '100rem' }}
						size="small"
						stripedRows
						cellClassName="table-cell"
						rowClassName={rowClass}
						filters={globalFilter}
					>
						<Column field="start_time" header="Date" style={{ width: '13%' }} sortable body={startDateTemplate} />
						<Column
							field="professional"
							header="Professional"
							sortable
							style={{ width: '12%' }}
							body={professionalTemplate}
						/>
						<Column field="shift_type" header="Shift type" style={{ width: '8%' }} body={shiftTypeTemplate} />
						<Column field="facility_name" header="Facility" style={{ width: '15%' }} sortable body={facilityTemplate} />
						<Column field="unit" header="Unit" style={{ width: '10%' }} sortable body={unitTemplate} />
						<Column field="start_hour_tz" header="Time" style={{ width: '12%' }} body={timePeriodTemplate} />
						<Column
							field="estimated_pay"
							header="Est. pay"
							style={{ width: '7%' }}
							sortable
							body={estimatedPayTemplate}
						/>
						<Column field="rate" header="Rate" style={{ width: '5%' }} sortable body={rateTemplate} />
						<Column field="facility_city" header="City" style={{ width: '10%' }} sortable body={cityTemplate} />
						<Column field="facility_state_code" header="State" style={{ width: '5%' }} sortable body={stateTemplate} />
						<Column field="facility_zip_code" header="Zip" style={{ width: '5%' }} sortable body={zipTemplate} />
						<Column field="id" header="ID" style={{ width: '5%' }} sortable body={idTemplate} />
					</DataTable>
				</div>
			</div>
		</>
	);
};

export default AgencyAssignedShifts;
