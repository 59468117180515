/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import { withRouter, useHistory } from 'react-router';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import filterIcon from '../../assets/images/filters.svg';
import filterArrow from '../../assets/images/filter-arrow.svg';

import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import MultiSelectSearch from '../../components/MultiSelectSearch/MultiSelectSearch';
import { user } from '../../redux/user';
import {
	globalOverviewState,
	savePeriodIndex,
	saveSelectedFacilities,
	useShiftsQuery,
	useReopenShiftMutation,
	useShiftStructuresQuery,
	saveFilters
} from '../../redux/globalOverview';
import LeftSidebar from './LeftSidebar';
import calendarIcon from '../../assets/images/calendar.svg';
import arrowRight from '../../assets/images/arrow-right-black.svg';
import arrowLeft from '../../assets/images/arrow-left-black.svg';
import SingleActionModal from '../../components/modals/SingleActionModal';
import ReopenShiftModal from './ReopenShiftModal';
import GlobalOverviewSlot, { SLOT_ACTIONS } from './GlobalOverviewSlot';
import SLOT_STATUSES from '../../constants/slotStatuses';
import utils from '../../utils/utils';
import { showNotification } from '../../redux/notification';
import FilterUtils from '../../utils/FilterUtils';
import NoMatchingShifts from './NoMatchingShifts';

import { GLOBAL_OVERVIEW_SHIFT_STATUS } from '../../constants/ShiftStatus';
import './GlobalOverviewScreen.scss';
import { ENDPOINTS } from '../../constants';
import ProfessionalSearch from '../../components/ProfessionalSearch/ProfessionalSearch';

const getDateByOffset = (_periodIndex, offset) =>
	moment
		.utc()
		.day(offset)
		.subtract(-7 * _periodIndex, 'days');
const getFormattedDateByOffset = (_periodIndex, offset) => getDateByOffset(_periodIndex, offset).format('ddd M/D');

const _periodStart = (_periodIndex) => getDateByOffset(_periodIndex, 0);
const _periodEnd = (_periodIndex) => getDateByOffset(_periodIndex, 6);

const getSlotGroups = (_slots, caregiverFilter) => {
	return _slots.reduce(
		(acc, curr) => {
			if (caregiverFilter && curr.caregiver_id !== parseInt(caregiverFilter.caregiver_id, 10)) {
				return acc;
			}

			if (curr.slot_status === SLOT_STATUSES.FACILITY_CANCELLED) {
				acc.cancelled.push(curr);
			} else if (curr.caregiver_id) {
				acc.filled.push(curr);
			} else {
				acc.unfilled.push(curr);
			}

			return acc;
		},
		{ filled: [], unfilled: [], cancelled: [] }
	);
};

const TODAY = moment().format('ddd M/D');

const GlobalOverviewScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(user, shallowEqual) || {};
	const persistedOverviewState = useSelector(globalOverviewState, shallowEqual) || {};
	const [loading, setLoading] = useState(true);
	const [availableFacilities, setAvailableFacilities] = useState([]);
	const [selectedFacilities, setSelectedFacilities] = useState(persistedOverviewState.selectedFacilities);
	const [periodIndex, setPeriodIndex] = useState(persistedOverviewState.periodIndex);
	const [periodStart, setPeriodStart] = useState(_periodStart(periodIndex));
	const [periodEnd, setPeriodEnd] = useState(_periodEnd(periodIndex));
	const [matchingShifts, setMatchingShifts] = useState({
		haveShifts: true,
		reason: null
	});

	const [shiftsWeek, setShiftsWeek] = useState(null);
	const [showRefreshModal, setShowRefreshModal] = useState(false);
	const [shiftToReopen, setShiftToReopen] = useState(null);
	const [showReopenModal, setShowReopenModal] = useState(false);

	const { data: globalShifts, refetch: reloadShifts, isFetching, error } = useShiftsQuery(
		{ start: periodStart.format('YYYY-MM-DD'), end: periodEnd.format('YYYY-MM-DD') },
		{ refetchOnMountOrArgChange: true }
	);

	const { data: globalShiftStructures } = useShiftStructuresQuery();
	const [activeOptions, setActiveOptions] = useState({
		resourceTypes: [],
		statuses: persistedOverviewState.globalFilters.statuses
	});

	const selectedStatuses = useMemo(
		() =>
			Object.entries(activeOptions.statuses).reduce(
				(acc, [, status]) => ({ ...acc, ...(status.checked && { [status.value]: true }) }),
				{}
			),
		[activeOptions.statuses]
	);

	const selectedResourceTypes = useMemo(
		() =>
			activeOptions.resourceTypes.reduce((acc, item) => ({ ...acc, ...(item.checked && { [item.value]: true }) }), {}),
		[activeOptions.resourceTypes]
	);

	const [reopenShift] = useReopenShiftMutation();
	const [isCaregiverSelectOpen, setIsCaregiverSelectOpen] = useState(false);
	const [selectedCaregiverFilter, setSelectedCaregiverFilter] = useState(
		persistedOverviewState.globalFilters.selectedCaregiverFilter
	);
	const [expandFilters, setExpandFilters] = useState(true);

	useEffect(() => {
		if (error) {
			setLoading(false);
		}
	}, [error]);

	useEffect(() => {
		if (globalShiftStructures?.length && globalShiftStructures.length !== activeOptions?.resourceTypes?.length) {
			setActiveOptions({
				...activeOptions,
				resourceTypes:
					persistedOverviewState.globalFilters.resourceTypes.length > 0
						? persistedOverviewState.globalFilters.resourceTypes
						: FilterUtils.convertResourceTypesToOptions(globalShiftStructures)
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalShiftStructures]);

	useEffect(() => {
		if (userData.facilitiesEnabled) {
			const sortByAlpha = (a, b) => a.label.localeCompare(b.label);
			const enabledFacilities = userData.facilitiesEnabled
				.filter((fc) => fc.allow_flex_shifts && !!fc.is_global_calendar_enabled)
				.sort(sortByAlpha);

			setAvailableFacilities(enabledFacilities);
			if (!persistedOverviewState.selectedFacilities.length) {
				setSelectedFacilities(enabledFacilities.map((f) => ({ ...f, checked: true })));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData.facilitiesEnabled]);

	const initShiftsWeek = (index) => ({
		[getFormattedDateByOffset(index, 0)]: {
			shifts: [],
			filledSlotsCount: 0,
			unfilledSlotsCount: 0,
			filteredShiftsCount: 0
		},
		[getFormattedDateByOffset(index, 1)]: {
			shifts: [],
			filledSlotsCount: 0,
			unfilledSlotsCount: 0,
			filteredShiftsCount: 0
		},
		[getFormattedDateByOffset(index, 2)]: {
			shifts: [],
			filledSlotsCount: 0,
			unfilledSlotsCount: 0,
			filteredShiftsCount: 0
		},
		[getFormattedDateByOffset(index, 3)]: {
			shifts: [],
			filledSlotsCount: 0,
			unfilledSlotsCount: 0,
			filteredShiftsCount: 0
		},
		[getFormattedDateByOffset(index, 4)]: {
			shifts: [],
			filledSlotsCount: 0,
			unfilledSlotsCount: 0,
			filteredShiftsCount: 0
		},
		[getFormattedDateByOffset(index, 5)]: {
			shifts: [],
			filledSlotsCount: 0,
			unfilledSlotsCount: 0,
			filteredShiftsCount: 0
		},
		[getFormattedDateByOffset(index, 6)]: {
			shifts: [],
			filledSlotsCount: 0,
			unfilledSlotsCount: 0,
			filteredShiftsCount: 0
		}
	});

	const showFilled = selectedStatuses[GLOBAL_OVERVIEW_SHIFT_STATUS.FILLED.name];
	const showOpen = selectedStatuses[GLOBAL_OVERVIEW_SHIFT_STATUS.OPEN.name];
	const showCancelled = selectedStatuses[GLOBAL_OVERVIEW_SHIFT_STATUS.CANCELLED.name];

	const groupShifts = (shifts) => {
		const selectedFacilitiesIds = selectedFacilities.reduce((acc, item) => ({ ...acc, [item.id]: true }), {});
		const days = initShiftsWeek(periodIndex);
		const areAllResourceTypesSelected =
			Object.keys(selectedResourceTypes).length === Object.keys(activeOptions.resourceTypes).length;

		for (let i = 0; i < shifts.length; i++) {
			const shift = { ...shifts[i] };

			if (
				selectedFacilitiesIds[shift.facility_id] &&
				(areAllResourceTypesSelected || selectedResourceTypes[shift.shift_resource_type])
			) {
				const { filled, unfilled, cancelled } = getSlotGroups(shift.slots, selectedCaregiverFilter);
				shift.filledSlots = filled;
				shift.unfilledSlots = unfilled;
				shift.cancelledSlots = cancelled;
				const startDate = moment.tz(shift.start_time, shift.facility_timezone);
				const endDate = moment.tz(shift.end_time, shift.facility_timezone);
				const shiftDay = startDate.format('ddd M/D');
				shift.formatted_start_time = startDate.format('LT');
				shift.formatted_end_time = endDate.format('LT');
				shift.isUpcomingShift = endDate.isSameOrAfter(moment.tz(shift.facility_timezone));
				if (days[shiftDay]) {
					days[shiftDay].shifts.push(shift);
					days[shiftDay].filledSlotsCount += filled.length;
					days[shiftDay].unfilledSlotsCount += unfilled.length;

					let localCount = 0;
					if (showFilled) localCount += filled.length;
					if (showOpen) localCount += unfilled.length;
					if (showCancelled) localCount += cancelled.length;
					days[shiftDay].filteredShiftsCount += localCount;
				}
			}
		}
		setShiftsWeek(days);
		setLoading(false);
	};

	useEffect(
		() => globalShifts && groupShifts(globalShifts),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[globalShifts, selectedFacilities, showRefreshModal, activeOptions]
	);

	const onFacilityFilter = (_selectedFacilities) => {
		setSelectedFacilities(_selectedFacilities);
		setLoading(true);
		dispatch(saveSelectedFacilities(_selectedFacilities));
	};

	const onSelectToday = () => {
		if (periodIndex !== 0) {
			setPeriodIndex(0);
			setPeriodStart(_periodStart(0));
			setPeriodEnd(_periodEnd(0));
			setLoading(true);
			dispatch(savePeriodIndex(0));
		}
	};

	const onPreviousPeriod = () => {
		if (periodIndex > -26) {
			/* 6 months in the past */
			setPeriodIndex(periodIndex - 1);
			setPeriodStart(_periodStart(periodIndex - 1));
			setPeriodEnd(_periodEnd(periodIndex - 1));
			setLoading(true);
			dispatch(savePeriodIndex(periodIndex - 1));
		}
	};
	const onNextPeriod = () => {
		if (periodIndex < 104) {
			/* 2 years in the future */
			setPeriodIndex(periodIndex + 1);
			setPeriodStart(_periodStart(periodIndex + 1));
			setPeriodEnd(_periodEnd(periodIndex + 1));
			setLoading(true);
			dispatch(savePeriodIndex(periodIndex + 1));
		}
	};

	const onReopenShift = async () => {
		try {
			await reopenShift({ slot: shiftToReopen.slot }).unwrap();
			dispatch(showNotification('success', 'Shift has been successfully reopened.'));
		} catch (reopenError) {
			const fallbackMessage = `There was an error reopening the shift.`;
			dispatch(showNotification('error', utils.parseRTKQueryValidationError(reopenError, fallbackMessage)));
		} finally {
			setShowReopenModal(false);
		}
	};

	const handleSlotAction = (action, shift, slot) => {
		if (action === SLOT_ACTIONS.GO_TO_SHIFT) {
			history.push(`/private/shifts?slotId=${slot.slot_id}&facilityId=${shift.facility_id}&fromGlobalOverview=true`);
			return;
		}

		if (action === SLOT_ACTIONS.REOPEN_SHIFT) {
			setShiftToReopen({ shift, slot });
			setShowReopenModal(true);
		}
	};

	const removeSelectedCaregiver = () => {
		setSelectedCaregiverFilter(null);
		setIsCaregiverSelectOpen(false);
		groupShifts(globalShifts);
		dispatch(saveFilters({ ...persistedOverviewState.globalFilters, selectedCaregiverFilter: null }));
	};

	useEffect(() => {
		if (globalShifts) {
			groupShifts(globalShifts);
		}
	}, [selectedCaregiverFilter]);

	useEffect(() => {
		if (globalShifts) {
			groupShifts(globalShifts);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCaregiverFilter]);

	const handleExpandFilters = () => {
		setExpandFilters(!expandFilters);
	};

	const filterCount = useMemo(() => {
		let count = 0;
		if (Object.keys(selectedStatuses).length < Object.keys(GLOBAL_OVERVIEW_SHIFT_STATUS).length) {
			count += 1;
		}

		if (Object.keys(selectedResourceTypes).length < Object.keys(activeOptions.resourceTypes).length) {
			count += 1;
		}

		if (selectedCaregiverFilter) {
			count += 1;
		}

		return count;
	}, [selectedStatuses, activeOptions, selectedCaregiverFilter]);

	const handleClearAll = () => {
		const convertedStatuses = FilterUtils.convertGlobalOverviewShiftStatusToOptions(GLOBAL_OVERVIEW_SHIFT_STATUS).map(
			(status) => ({
				...status,
				checked: true
			})
		);

		const checkedResourceTypes = FilterUtils.convertResourceTypesToOptions(globalShiftStructures).map(
			(resourceType) => ({
				...resourceType,
				checked: true
			})
		);
		setActiveOptions({
			resourceTypes: checkedResourceTypes,
			statuses: convertedStatuses
		});
		setSelectedCaregiverFilter(null);
		dispatch(
			saveFilters({ resourceTypes: checkedResourceTypes, statuses: convertedStatuses, selectedCaregiverFilter: null })
		);
	};

	const NOT_MATCHING_SHIFTS_MAP = useMemo(
		() => ({
			NO_SHIFTS: {
				labels: {
					title: 'None of your facilities have shifts for this week.',
					subtitle: ''
				},
				labelBtn: '',
				actionBtn: () => {}
			},
			NO_SHIFTS_WITH_FILTERS: {
				labels: {
					title: 'There are currently no shifts to display this week.',
					subtitle: 'Clear or update your filters to see more shifts.'
				},
				labelBtn: 'Clear Filters',
				actionBtn: handleClearAll
			},
			NO_SHIFT_FACILITIES_SELECTED: {
				labels: {
					title: 'There are currently no shifts at your selected facilities.',
					subtitle: 'Reset or update your facilities filter to see more shifts.'
				},
				labelBtn: 'Reset Facilities',
				actionBtn: () => {
					setSelectedFacilities(availableFacilities.map((f) => ({ ...f, checked: true })));
					setAvailableFacilities([...availableFacilities]);
				}
			}
		}),
		[availableFacilities, handleClearAll, availableFacilities]
	);

	useEffect(() => {
		let reason = null;
		let haveShifts = true;
		if (shiftsWeek) {
			const totalWeekShifts = Object.entries(shiftsWeek)?.reduce((acc, [, week]) => acc + week.filteredShiftsCount, 0);
			const areAllFacilitiesSelected = selectedFacilities.length === availableFacilities.length;

			if (!areAllFacilitiesSelected && totalWeekShifts === 0 && globalShifts.length > 0) {
				reason = NOT_MATCHING_SHIFTS_MAP.NO_SHIFT_FACILITIES_SELECTED;
			} else if (filterCount > 0 && totalWeekShifts === 0 && globalShifts.length > 0) {
				reason = NOT_MATCHING_SHIFTS_MAP.NO_SHIFTS_WITH_FILTERS;
			} else if (totalWeekShifts === 0) {
				reason = NOT_MATCHING_SHIFTS_MAP.NO_SHIFTS;
			}

			haveShifts = !reason;
		}

		setMatchingShifts({
			haveShifts,
			reason
		});
	}, [shiftsWeek, selectedFacilities, availableFacilities]);

	const handleCaregiverFilterSelection = (caregiver) => {
		dispatch(saveFilters({ ...persistedOverviewState.globalFilters, selectedCaregiverFilter: caregiver }));
		setSelectedCaregiverFilter(caregiver);
	};

	const handleMultiSelectFiltersApply = (key, options) => {
		setActiveOptions((prev) => ({ ...prev, [key]: options }));

		const updatedFilters = {
			...persistedOverviewState.globalFilters,
			[key]: options.map((option) => ({ ...option, checked: option.checked }))
		};
		dispatch(saveFilters(updatedFilters));
	};

	return (
		<>
			{showRefreshModal && (
				<SingleActionModal
					confirmModal
					title="Refresh the Page"
					actionLabel="Refresh Now"
					onAction={() => {
						setShowRefreshModal(false);
						reloadShifts({
							start: periodStart.format('YYYY-MM-DD'),
							end: periodEnd.format('YYYY-MM-DD')
						});
					}}
					className="refresh-modal"
					onHide={() => setShowRefreshModal(false)}
					disableClickOutside
				>
					The shift you selected occurs in the past and cannot be displayed. Refresh now to reflect the latest changes.
				</SingleActionModal>
			)}
			{showReopenModal && (
				<ReopenShiftModal
					onCancel={() => setShowReopenModal(false)}
					setShowModal={showReopenModal}
					onConfirm={onReopenShift}
					shiftToReopen={shiftToReopen.shift}
				/>
			)}
			<div className="global-overview-screen">
				<LeftSidebar
					availableFacilities={availableFacilities}
					selectedFacilities={selectedFacilities}
					onApply={onFacilityFilter}
				/>

				<div className="overview-container">
					<div className="heading">
						<img src={calendarIcon} alt="Overview" className="calendar-icon" />
						<span>Global Schedule</span>
					</div>

					<div className="timeframe-selector-container">
						<button type="button" className="button secondary-button" onClick={onSelectToday}>
							Today
						</button>
						<div className="shifts-period">
							<img
								src={arrowLeft}
								alt="Previous period"
								className={classNames('shifts-period-nav', { disabled: periodIndex === -26 })}
								onClick={onPreviousPeriod}
								aria-hidden="true"
							/>
							<div className="d-flex align-items-center">
								<div className="nav-date-item d-flex justify-content-end">{periodStart.format('ddd, MMM D, YYYY')}</div>
								<span className="dates-separate">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
								<div className="nav-date-item">{periodEnd.format('ddd, MMM D, YYYY')}</div>
							</div>
							<img
								src={arrowRight}
								alt="Next period"
								className={classNames('shifts-period-nav', { disabled: periodIndex === 104 })}
								onClick={onNextPeriod}
								aria-hidden="true"
							/>
						</div>
						<div className="view-by-container">
							{filterCount > 0 && (
								<button type="button" onClick={handleClearAll} className="filter-btn clear">
									Clear all
								</button>
							)}
							<div className={classNames('filter-container', { noFilterCount: filterCount === 0 })}>
								<img src={filterIcon} alt="filters" />
								<div className="filter-text">Filters</div>
								{filterCount > 0 && <div className="filter-number">{filterCount}</div>}
								<button type="button" className="filter-btn" onClick={handleExpandFilters}>
									<img src={filterArrow} alt="expand" className={classNames('arrow-icon', { down: !expandFilters })} />
								</button>
							</div>
						</div>
					</div>
					{expandFilters && (
						<div className="filters-container">
							<div className="inline-filters">
								<MultiSelectSearch
									showLongSelectionText
									className="select-filter-type-container"
									label="Shift Type"
									options={activeOptions.resourceTypes}
									onApply={(options) => handleMultiSelectFiltersApply('resourceTypes', options)}
								/>
								<MultiSelectSearch
									showLongSelectionText
									className="select-filter-status-container"
									label="Status"
									options={activeOptions.statuses}
									onApply={(options) => handleMultiSelectFiltersApply('statuses', options)}
								/>
							</div>
							<ProfessionalSearch
								className="filter-item push-right"
								selectedCaregiver={selectedCaregiverFilter}
								setSelectedCaregiver={handleCaregiverFilterSelection}
								isOpen={isCaregiverSelectOpen}
								setIsOpen={setIsCaregiverSelectOpen}
								removeSelectedCaregiver={removeSelectedCaregiver}
								emptyListPlaceholder="No enabled professionals found"
								placeholder="Search for Professional"
								clearVariantButton="WARNING"
								textVariant="BOLD"
								searchURL={ENDPOINTS.SEARCH_ACTIVE_WORKER}
							/>
						</div>
					)}

					<div className="week-days-container">
						{shiftsWeek &&
							[...Array(7).keys()].map((i) => {
								const day = getFormattedDateByOffset(periodIndex, i);
								return (
									<div className="col" key={i}>
										<div className="summary-box">
											<div className={classNames('day-label', { 'today-label': !periodIndex && day === TODAY })}>
												{day}
											</div>
											<div className="summary-container">
												<div className="filled-count">Filled Shifts: {shiftsWeek[day]?.filledSlotsCount}</div>
												<div className="unfilled-count">Open Shifts: {shiftsWeek[day]?.unfilledSlotsCount}</div>
											</div>
										</div>

										{matchingShifts.haveShifts && (
											<div className="shifts-container">
												{shiftsWeek[day]?.shifts.map((shift) =>
													(showFilled && shift.filledSlots.length) ||
													(showOpen && shift.unfilledSlots.length) ||
													(showCancelled && shift.cancelledSlots.length) ? (
														<div className="shift-container" key={`shift-${shift.shift_id}`}>
															<div
																className={classNames('shift-box', {
																	unfilled: !!shift.unfilledSlots.length,
																	cancelled: showCancelled && !shift.unfilledSlots.length && !shift.filledSlots.length
																})}
															>
																<div className="facility-name">{shift.facility_name}</div>
																<div>
																	{shift.shift_resource_type}
																	{shift.shift_specialty && ` - ${shift.shift_specialty}`}
																</div>
																<div>
																	{shift.formatted_start_time} - {shift.formatted_end_time}
																</div>
																<div className="fill-summary">
																	{shift.filledSlots.length} of {shift.slots.length - shift.cancelledSlots.length}{' '}
																	Filled
																</div>
															</div>
															{showFilled &&
																shift.filledSlots.map((slot) => (
																	<GlobalOverviewSlot
																		key={`filled-slot-${slot.slot_id}`}
																		slot={slot}
																		shift={shift}
																		showRefreshModal={() => setShowRefreshModal(true)}
																		handleSlotAction={handleSlotAction}
																		isLastDay={i === 6}
																	/>
																))}
															{showOpen &&
																shift.unfilledSlots.map((slot) => (
																	<GlobalOverviewSlot
																		key={`unfilled-slot-${slot.slot_id}`}
																		slot={slot}
																		shift={shift}
																		customClass="unfilled"
																		showRefreshModal={() => setShowRefreshModal(true)}
																		handleSlotAction={handleSlotAction}
																		isLastDay={i === 6}
																	/>
																))}
															{showCancelled &&
																shift.cancelledSlots.map((slot) => (
																	<GlobalOverviewSlot
																		key={`cancelled-slot-${slot.slot_id}`}
																		slot={slot}
																		shift={shift}
																		customClass="cancelled"
																		showRefreshModal={() => setShowRefreshModal(true)}
																		handleSlotAction={handleSlotAction}
																		isLastDay={i === 6}
																	/>
																))}
														</div>
													) : (
														''
													)
												)}
											</div>
										)}
									</div>
								);
							})}
					</div>
					{!matchingShifts.haveShifts && <NoMatchingShifts reason={matchingShifts.reason} />}
				</div>
				{(loading || isFetching) && <LoadingSpinner />}
			</div>
		</>
	);
};

export default withRouter(GlobalOverviewScreen);
