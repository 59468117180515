import React from 'react';

/**
 * RemoveIcon component displays an icon representing a logout action.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=24] - Width of the icon.
 * @param {number} [props.height=25] - Height of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} RemoveIcon component.
 */
const RemoveIcon = ({ width = 24, height = 25, className }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 25"
		fill="none"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			id="mask0_4288_98767"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="25"
		>
			<rect y="0.5" width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_4288_98767)">
			<path
				d="M12 13.9L14.9 16.8C15.0833 16.9833 15.3167 17.075 15.6 17.075C15.8833 17.075 16.1167 16.9833 16.3 16.8C16.4833 16.6167 16.575 16.3833 16.575 16.1C16.575 15.8167 16.4833 15.5833 16.3 15.4L13.4 12.5L16.3 9.6C16.4833 9.41667 16.575 9.18333 16.575 8.9C16.575 8.61667 16.4833 8.38333 16.3 8.2C16.1167 8.01667 15.8833 7.925 15.6 7.925C15.3167 7.925 15.0833 8.01667 14.9 8.2L12 11.1L9.1 8.2C8.91667 8.01667 8.68333 7.925 8.4 7.925C8.11667 7.925 7.88333 8.01667 7.7 8.2C7.51667 8.38333 7.425 8.61667 7.425 8.9C7.425 9.18333 7.51667 9.41667 7.7 9.6L10.6 12.5L7.7 15.4C7.51667 15.5833 7.425 15.8167 7.425 16.1C7.425 16.3833 7.51667 16.6167 7.7 16.8C7.88333 16.9833 8.11667 17.075 8.4 17.075C8.68333 17.075 8.91667 16.9833 9.1 16.8L12 13.9ZM12 22.5C10.6167 22.5 9.31667 22.2375 8.1 21.7125C6.88333 21.1875 5.825 20.475 4.925 19.575C4.025 18.675 3.3125 17.6167 2.7875 16.4C2.2625 15.1833 2 13.8833 2 12.5C2 11.1167 2.2625 9.81667 2.7875 8.6C3.3125 7.38333 4.025 6.325 4.925 5.425C5.825 4.525 6.88333 3.8125 8.1 3.2875C9.31667 2.7625 10.6167 2.5 12 2.5C13.3833 2.5 14.6833 2.7625 15.9 3.2875C17.1167 3.8125 18.175 4.525 19.075 5.425C19.975 6.325 20.6875 7.38333 21.2125 8.6C21.7375 9.81667 22 11.1167 22 12.5C22 13.8833 21.7375 15.1833 21.2125 16.4C20.6875 17.6167 19.975 18.675 19.075 19.575C18.175 20.475 17.1167 21.1875 15.9 21.7125C14.6833 22.2375 13.3833 22.5 12 22.5ZM12 20.5C14.2333 20.5 16.125 19.725 17.675 18.175C19.225 16.625 20 14.7333 20 12.5C20 10.2667 19.225 8.375 17.675 6.825C16.125 5.275 14.2333 4.5 12 4.5C9.76667 4.5 7.875 5.275 6.325 6.825C4.775 8.375 4 10.2667 4 12.5C4 14.7333 4.775 16.625 6.325 18.175C7.875 19.725 9.76667 20.5 12 20.5Z"
				fill="#A11010"
			/>
		</g>
	</svg>
);

export default RemoveIcon;
