/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';

const CheckBoxSection = ({
	selectAllOption = false,
	onSelectAll = () => {},
	checkboxOptions = [],
	onCheckboxChange = () => {},
	id = 'default-checkbox'
}) => (
	<>
		<div className="checkbox-item select-all" key="select-all">
			<input
				type="checkbox"
				id={`select-all-${id}`}
				value={selectAllOption}
				checked={selectAllOption}
				onChange={onSelectAll}
			/>
			<label className="checkbox-item-label" htmlFor={`select-all-${id}`}>
				Select all ({checkboxOptions.length})
			</label>
		</div>

		{checkboxOptions.map((option) => (
			<div className="checkbox-item" key={option.value}>
				<input
					type="checkbox"
					id={option.label}
					value={option.checked}
					checked={option.checked}
					onChange={() => onCheckboxChange(option.value)}
				/>
				<label className="checkbox-item-label" htmlFor={option.label}>
					{option.label}
				</label>
			</div>
		))}
	</>
);

export default memo(CheckBoxSection);
