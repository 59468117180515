import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useFlexWorkersQuery, useUpdateFlexWorkerStatusMutation } from '../../redux/professionals';
import useFilters from '../../hooks/useFilters';
import { applyFilters } from '../../utils/FilterFunctions';
import CaregiverCardInternalStaff from '../../components/cards/CaregiverCardInternalStaff';
import CaregiverSearch from '../../components/CaregiverSearch/CaregiverSearch';
import ProfessionalsList from './ProfessionalsList';
import WarningIcon from '../../components/icons/WarningIcon';
import BaseModal from '../../components/modals/BaseModal/BaseModal';
import { showNotification } from '../../redux/notification';
import utils from '../../utils/utils';

const Counter = ({ count, professionalType }) => (
	<span className="professionals-screen__counter">{`${count} ${professionalType}${count !== 1 ? 's' : ''}`}</span>
);

const mapCaregiversData = (data) => {
	return data?.map((caregiver) => {
		const { name, id, resource_types: resourceTypes, specialties } = caregiver;
		const resourceAndSpecialties = [];
		const augmentedSpecialties = [];

		resourceTypes.forEach((resourceType) => {
			const specialtiesForResource = specialties.filter((specialty) => specialty.certification_id === resourceType.id);
			if (specialtiesForResource.length > 0) {
				resourceAndSpecialties.push(
					`${resourceType.code} - ${specialtiesForResource.map((spec) => `${spec.label}`).join(', ')}`
				);
				augmentedSpecialties.push({
					label: `${resourceType.code} - ${specialtiesForResource.map((spec) => `${spec.label}`).join(', ')}`
				});
			} else {
				resourceAndSpecialties.push(resourceType.code);
				// hack to display resources without specialties
				augmentedSpecialties.push({ label: resourceType.code });
			}
		});

		return {
			...caregiver,
			caregiverName: name,
			// for the caregiver card details, augmented specialties contain resource types as well
			resourceType: null,
			specialties: augmentedSpecialties,
			certificationType: resourceAndSpecialties.flat().join(', '),
			caregiverId: id
		};
	});
};

const InternalStaff = () => {
	const dispatch = useDispatch();
	const { filters, setFilters } = useFilters();
	const { data, isLoading, refetch } = useFlexWorkersQuery(undefined, {
		refetchOnMountOrArgChange: true
	});

	const [showEnableConfirmationModal, setShowEnableConfirmationModal] = useState(false);
	const [showDisableConfirmationModal, setShowDisableConfirmationModal] = useState(false);
	const [caregiverToBeUpdated, setCaregiverToBeUpdated] = useState(null);
	const [updateFlexWorkerStatus, { isLoading: isSaving }] = useUpdateFlexWorkerStatusMutation();
	const caregivers = useMemo(() => mapCaregiversData(data || []), [data]);

	let filteredCaregivers = useMemo(() => {
		return applyFilters({ items: caregivers, filters });
	}, [caregivers, filters]);

	filteredCaregivers = useMemo(() => filteredCaregivers.sort((a, b) => b.is_active - a.is_active), [
		filteredCaregivers
	]);

	const [selectedCaregiver, setSelectedCaregiver] = useState();
	const [isCaregiverSelectOpen, setIsCaregiverSelectOpen] = useState(false);

	const onCaregiverSelect = (options) => setFilters({ ...filters, caregiver: options || [] });

	const onCaregiverWorkerStatusAction = async (shouldEnable, caregiver) => {
		if (!shouldEnable) {
			// Manually refreshes data
			const { data: newData } = await refetch();

			// Get the latest version and set the caregiver to be updated using the latest version of caregiver
			const foundCg = mapCaregiversData(newData || []).find((t) => t.id === caregiver.id);

			setCaregiverToBeUpdated(foundCg || caregiver);
		} else {
			setCaregiverToBeUpdated(caregiver);
		}
		if (shouldEnable) {
			setShowEnableConfirmationModal(true);
		} else {
			setShowDisableConfirmationModal(true);
		}
	};

	const onConfirmationModalHide = () => {
		setShowEnableConfirmationModal(false);
		setCaregiverToBeUpdated(null);
		setShowDisableConfirmationModal(false);
	};

	const postWorkerFacilityStatusUpdate = async (isDisabled) => {
		try {
			await updateFlexWorkerStatus({
				workerId: caregiverToBeUpdated.id,
				isDisabled
			}).unwrap();
			dispatch(showNotification('success', `Employee has successfully been ${isDisabled ? 'disabled' : 'enabled'}`));
		} catch (error) {
			const fallbackMessage = `There was an error changing the worker's status.`;
			dispatch(showNotification('error', utils.parseRTKQueryValidationError(error, fallbackMessage)));
		} finally {
			onConfirmationModalHide();
		}
	};

	const removeSelectedCaregiver = () => {
		onCaregiverSelect(null);
		setSelectedCaregiver(null);
		setIsCaregiverSelectOpen(false);
	};

	const caregiversCount = filteredCaregivers.length;

	return [
		<div className="professionals-screen">
			<div className="professionals-screen__filters-section">
				<CaregiverSearch
					caregivers={caregivers}
					className="filter-item push-right"
					onCaregiverSelect={onCaregiverSelect}
					selectedCaregiver={selectedCaregiver}
					setSelectedCaregiver={setSelectedCaregiver}
					isOpen={isCaregiverSelectOpen}
					setIsOpen={setIsCaregiverSelectOpen}
					removeSelectedCaregiver={removeSelectedCaregiver}
					emptyListPlaceholder="No professionals found"
					placeholder="Search for Employee"
				/>
			</div>
			<ProfessionalsList
				showLoadingSpinner={isLoading || isSaving}
				filteredCaregivers={filteredCaregivers}
				Counter={<Counter count={caregiversCount} professionalType="Employee" />}
				CaregiverCard={CaregiverCardInternalStaff}
				onCaregiverWorkerStatusAction={onCaregiverWorkerStatusAction}
			/>
		</div>,

		showEnableConfirmationModal && caregiverToBeUpdated && (
			<BaseModal
				confirmModal
				className="primary-colors"
				key="confirmation-modal-enable-worker"
				title="Enable Employee?"
				primaryActionLabel="Enable"
				onPrimaryAction={() => postWorkerFacilityStatusUpdate(false)}
				secondaryActionLabel="Cancel"
				onSecondaryAction={onConfirmationModalHide}
				onHide={onConfirmationModalHide}
				primaryActionDisabled={isLoading || isSaving}
				secondaryActionDisabled={isLoading || isSaving}
			>
				<div>
					Are you sure you want to <b>enable</b> {caregiverToBeUpdated.name}? An enabled worker can claim or be assigned
					shifts at this facility.
				</div>
			</BaseModal>
		),

		showDisableConfirmationModal && caregiverToBeUpdated && (
			<BaseModal
				confirmModal
				key="confirmation-modal-disable-worker"
				titleIcon={<WarningIcon width={80} height={80} />}
				title="Disable Employee?"
				primaryActionLabel="Disable"
				secondaryActionLabel="Cancel"
				onPrimaryAction={() => postWorkerFacilityStatusUpdate(true)}
				onSecondaryAction={onConfirmationModalHide}
				onHide={onConfirmationModalHide}
			>
				{caregiverToBeUpdated.upcoming_facility_shifts_count > 0 ? (
					<div>
						The worker has{' '}
						<b>
							{caregiverToBeUpdated.upcoming_facility_shifts_count} upcoming{' '}
							{utils.pluralize('shift', 's', caregiverToBeUpdated.upcoming_facility_shifts_count)}
						</b>{' '}
						at this facility and will be removed and a replacement shift will be added.
						<br />
						Are you sure you want to <b>disable</b> {caregiverToBeUpdated.name}?
					</div>
				) : (
					<div>
						Are you sure you want to <b>disable</b> {caregiverToBeUpdated.name}? <br />A disabled worker cannot claim
						shifts at this facility.
					</div>
				)}
			</BaseModal>
		)
	];
};

export default InternalStaff;
