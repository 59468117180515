/**
 * Filter utils for various shift properties.
 *
 * @namespace FilterUtils
 * @type {Object}
 */
const FilterUtils = {
	convertResourceTypesToOptions: (structures) =>
		[...new Set(structures.map((e) => e.resource_type))].map((e) => ({ id: e, label: e, value: e, checked: true })),

	convertGlobalOverviewShiftStatusToOptions: (statuses) =>
		Object.entries(statuses).map(([, status]) => ({
			id: status.name,
			label: status.name,
			value: status.name,
			checked: status.isDefault
		}))
};

export default FilterUtils;
